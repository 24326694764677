import React from 'react'
import { Link } from 'gatsby'
import { Card as CardBase, Text, Button } from 'components/atoms'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const CardAction = ({ title, message, action, link }) => {
  return (
    <Wrapper>
      <Card>
        <CardTitle>
          <Text modifiers={['sm', 'bold', 'inverse', 'alternative']}>
            {title}
          </Text>
        </CardTitle>
        <Text modifiers={['inverse']}>
          {message}
        </Text>
      </Card>
      <Action>
        <Link to={link}>
          <Button modifiers={['stretched']}>
            {action}
          </Button>
        </Link>
      </Action>
    </Wrapper>
  )
}

const Action = styled.div`
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -50%);
  padding-right: ${ props => props.theme.spacing.m };
  button {
    transition: all 0.2s ease;
    box-shadow: 0 5px 10px rgba(0,0,0,0.12);
    &:hover {
      box-shadow: 0 8px 30px rgba(0,0,0,0.12);
      transform: translate3d(0px,-1px,0px);
    }
`

const Wrapper = styled.div`
  position: relative;
  animation-name: float;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  @keyframes float {
    0% {
      box-shadow: 0 5px 10px rgba(0,0,0,0.12);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 10px 10px rgba(0,0,0,0.12);
      transform: translatey(-10px);
    }
    100% {
      box-shadow: 0 5px 10px rgba(0,0,0,0.12);
      transform: translatey(0px);
    }
  }
`

const Card = styled(CardBase)(props => css`
  cursor: pointer;
  background-color: ${ props.theme.color.bgInverse };
  padding-bottom: ${ props.theme.spacing.l };
`)

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${ props => props.theme.spacing.xs };
`

CardAction.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

CardAction.defaultProps = {
  title: 'Esperamos o seu contacto',
  message: 'Venha aproveitar o sossego da Fajã.',
  action: 'Contactar',
  link: '/'
}

export default CardAction
