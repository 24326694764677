import React from 'react'
import {
  Container,
  Title,
  Spacer,
  Reveal
} from 'components/atoms'
import { ActivitieCard } from 'components/molecules'
import styled, { css } from 'styled-components'
import { above, below } from 'utils/breakpoints'
import { useStaticQuery, graphql } from 'gatsby'
import { Element } from 'react-scroll'

const Path = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97"
    height="1990"
    viewBox="0 0 97 1990"
  >
    <path
      fill="none"
      stroke="#EAEAEA"
      strokeDasharray="1,40"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
      d="M80.079 6C30.693 81.028 6 164.853 6 257.478c0 138.936 84.095 113.154 84.095 264.981 0 151.828-39.197 193.365-39.197 239.2 0 45.835 10.338 119.218 29.18 184.86 12.562 43.76 12.562 82.05 0 114.871-37.435 84.11-56.152 159.917-56.152 227.422 0 101.259 66.17 111.999 66.17 171.066 0 59.067-42.254 200.182-54.175 295.152S19 1890 36 1999"
    ></path>
  </svg>
)

const query = graphql`
  query {
    davidSurf: file(relativePath: { eq: "david-surf.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    churchLeft: file(relativePath: { eq: "esquerda-da-igreja.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    antonioSurf: file(relativePath: { eq: "surf-antonio.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lagoa: file(relativePath: { eq: "lagoa.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    passeios: file(relativePath: { eq: "passeios.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const translationStart = {
  pt: [
    {
      name: 'Passeios pedestres',
      message: 'Disponibilizamos sempre um guia para os diversos percursos que temos preparados para si. Venha conhecer melhor esta Fajã através de uma caminhada onde pode estar em contacto com as belas paisagens do local.',
    },
    {
      name: 'Banhos na lagoa  🏊‍♂️',
      message: 'Esta lagoa fica sob uma enorme falésia e foi formada pelo desmoronamento das montanhas da costa norte da ilha. As suas águas cristalinas e tranquilas são o ideal para descansar.',
    }
  ],
  en: [
    {
      name: 'Hiking',
      message: 'We have a guide available to take you on the several hiking trails we prepared for you. Discover this fajã by taking trails that allow you to be in touch with this place’s beautiful landscape.',
    },
    {
      name: 'Bathing in the lake 🏊‍♂️',
      message: 'The lake is located at the bottom of a huge cliff, having been formed by the landslide of the northern mountains. Its crystal-clear and tranquil waters are ideal to relax.',
    }
  ],
}

const translationEnd = {
  pt: [
    {
      name: 'Surf e bodyboard 🌊',
      message: 'Ondas de classe mundial de nome Lago de Linho, Esquerda da Igreja, Feiticeiras e a Direita do Passe. Estas ondas podem ser surfadas por todos os praticantes e pode sempre ter umas aulas de surf. Caso não tenha prancha pode alugar no Camp!',
    },
    {
      name: 'Pesca submarina  🎣',
      message: 'Venha pescar nas nossas fantásticas águas e descobrir o encanto que estas têm. Pode alugar todo o equipamento no Camp!',
    },
    {
      name: 'Visitar a gruta e cascata',
      message: 'Venha visitar as grutas e também matar a sede e tomar banho numa nascente de águas cristalinas que dão forma à Cascata da Fajã de Santo Cristo que, depois de formar uma piscina natural, desce montanha abaixo ao encontro do mar.',
    },
  ],
  en: [
    {
      name: 'Surf and bodyboard 🌊',
      message: 'World-class waves – called Lago de Linho (Linen Lake), Esquerda da Igreja (Left from Church), Feiticeiras (Sorceresses) and Direita do Passe (Right from the Water flow) – that every surfer can ride. Nevertheless, if you wish, you can take some classes before. In case you have no boards, you can rent them at the Camp!',
    },
    {
      name: 'Spearfishing  🏊‍♂️',
      message: 'Discover the beauty of our waters by fishing in them. All equipment can be rented at the Camp!',
    },
    {
      name: 'Visiting the cave and the waterfall',
      message: 'Other activities include visiting caves or quenching your thirst and taking a dip at the crystalclear spring that forms the Waterfall of Fajã de Santo Cristo. In addition to creating a natural pool, the waterfall goes all the way down the mountain until it reaches the ocean.',
    },
  ]
}

const Activities = ({ langKey }) => {
  const data = useStaticQuery(query)

  const dataStartTranslation = translationStart[langKey]
  const imagesStart = [
    {
      images: [
        data.passeios.childImageSharp.fluid
      ]
    },
    {
      images: [
        data.lagoa.childImageSharp.fluid
      ]
    }
  ]

  const dataStart = dataStartTranslation.map((translation, index) => ({
    ...translation,
    ...imagesStart[index]
  }))

  const dataEndTranslation = translationEnd[langKey]
  const imagesEnd = [
    {
      images: [
        data.davidSurf.childImageSharp.fluid
      ]
    },
    {
    },
    {
    },
  ]
  const dataEnd = dataEndTranslation.map((translation, index) => ({
    ...translation,
    ...imagesEnd[index]
  }))

  return (
    <Element name="ativities">
      <Container>
        <Columns>
          <Column>
            <TitleWrapper>
              <Reveal effect="fadeInBottom">
                <Title modifiers={['ml', 'weightMedium']} withSeperator>
                  {langKey === 'pt'
                    ? (
                  <>
                    Atividades < br /> outdoor
                  </>
                    )
                    : (
                    <>
                  Outdoor < br /> activities
                  </>
                    )
                  }
                </Title>
              </Reveal>
            </TitleWrapper>
            <ActivityContainer>
              {
                dataStart.map((activity, index) => (
                  <Reveal effect="fadeInLeft" key={activity.name}>
                    <CardWrapper
                      className="card-wrapper--left"
                      isLast={dataStart.length - 1 === index}
                    >
                      <ActivitieCard
                        name={activity.name}
                        message={activity.message}
                        images={activity.images}
                      />
                    </CardWrapper>
                  </Reveal>
                ))
              }
            </ActivityContainer>
          </Column>
          <div className="path-container">
            <div className="path">
              <Reveal effect="fadeInTop">
                <Path />
              </Reveal>
            </div>
          </div>
          <Column>
            <ActivityContainer>
              <Spacer top="xl">
                {
                  dataEnd.map((activity, index) => (
                    <Reveal effect="fadeInRight" key={activity.name}>
                      <CardWrapper
                        className="card-wrapper--right"
                        isLast={dataEnd.length - 1 === index}
                      >
                        <ActivitieCard
                          name={activity.name}
                          message={activity.message}
                          images={activity.images}
                        />
                      </CardWrapper>
                    </Reveal>
                  ))
                }
              </Spacer>
            </ActivityContainer>
          </Column>
        </Columns>
      </Container>
    </Element>
  )
}

const Columns = styled.div`
  display: grid;
  .path-container {
    position: relative;
    display: none;
  }

  .path {
    position: absolute;
    transform: translateY(-5%);
    width: 90%;
  }

  a {
    text-decoration: none;
    &:hover,&:focus,&:active {
      color: ${ props => props.theme.color.base } ;
    }
  }

  ${ above.md(props => css`
      grid-template-columns: 1fr 84px 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: ${ props => props.theme.spacing.xs };
      grid-row-gap: 0px;

      .path-container {
        display: block;
      }

      .path {
        width: 100%;
      }
    `) }
  ${ above.lg(props => css`
    grid-column-gap: ${ props => props.theme.spacing.xxxl };
  `) }
`

const Column = styled.div(props => css`
  padding: 0 ${ props.theme.spacing.ms };
  ${ below.md(props => css`
    padding: 0;
  `) }
`)

const TitleWrapper = styled.div`
  max-width: 400px;
  padding-bottom: ${ props => props.theme.spacing.xxl };
  ${ below.md`
    padding-bottom: ${ props => props.theme.spacing.xs };
  ` }
`

const ActivityContainer = styled.div`
  ${ below.md`
    padding: 0;
  ` }

`

const CardWrapper = styled.div`
  max-width: 560px;
  padding-bottom: ${ props => props.isLast ? 0 : props.theme.spacing.xxxl };
  .card-wrapper--right {
    margin-right: auto;
  }
  .card-wrapper--left {
    margin-left: auto;
  }
  ${ below.md`
    max-width: 100%;
    padding-bottom: ${ props => props.theme.spacing.m };
  ` }
`

export default Activities
