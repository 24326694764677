import React, { useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Icon } from 'components/atoms'
import Logo from 'images/logo-google.svg'

const WidgetGoogle = ({ numberOfReviews, stars }) => {
  const starsArr = useMemo(() => Array(Math.round(stars)).fill(), [stars])
  return (
    <Widget
      href="https://www.google.com/travel/hotels/entity/CgsIl9Tlse6cycjPARAB/reviews"
      title="Google.com Reviews - Caldeira SurfCamp and Guesthouse"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Widget.Initial>
        <Logo />
      </Widget.Initial>
      <Widget.End>
        <Widget.Rating>{stars}</Widget.Rating>
        <Widget.Stars>
          {starsArr.map((el, i) => (
            <Icon icon="star" color="#E7711C" height={14} key={i} />
          ))}
        </Widget.Stars>
      </Widget.End>
    </Widget>
  )
}

WidgetGoogle.propTypes = {
  numberOfReviews: PropTypes.number.isRequired,
  stars: PropTypes.number.isRequired
}

const Widget = styled.a`
  display: inline-flex;
  padding: ${ props => props.theme.spacing.xxms };
  align-items: center;
  transition: background-color 0.1s ease-out;
  border-radius: ${ props => props.theme.value.borderRadius };

  text-decoration: none;
  color: ${ props => props.theme.color.base };

  &:hover {
    background-color: ${ props => props.theme.color.bg };
    cursor: pointer;
  }
`

Widget.Initial = styled.div`
  padding-right: ${ props => props.theme.spacing.xs };
  svg {
    height: 36px;
  }
`

Widget.End = styled.div`
  display: flex;
  align-items: center;
`

Widget.Stars = styled.div`
  display: flex;
`

Widget.Rating = styled.span`
  font-size: ${ props => props.theme.size.s };
  color: ${ props => props.theme.color.base };
  padding-right: ${ props => props.theme.spacing.xxs };
  font-weight: ${ props => props.theme.weight.medium };
  height: 36px;
  display: flex;
  align-items: center;
`

export default WidgetGoogle
