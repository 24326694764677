import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Nav } from 'components/organisms'
import { IntlProvider } from 'react-intl'
import {
  getCurrentLangKey,
  getLangs,
  getUrlForLang
} from 'ptz-i18n'
import en from 'data/messages/en'
import pt from 'data/messages/pt'
import { isHomePage } from 'utils/helpers'

const messages = { en, pt }

const Layout = ({ children, inverseTheme, location }) => {
  const url = location.pathname
  const data = useStaticQuery(query)

  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const isHome = isHomePage(url, false, langs)
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${ langKey !== 'pt' ? langKey : '' }`
  const langBtn = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(item => ({
    ...item,
    link: item.link.replace(`/${defaultLangKey}/`, '')
  }))
  const langContact = defaultLangKey === langKey ? '/contact' : `/${ langKey }/contact`

  return (
    <IntlProvider locale={langKey} messages={messages[langKey]}>
      <Nav
        navItems={data.site.siteMetadata.menu[langKey]}
        homeLink={homeLink}
        langBtn={langBtn}
        isHome={isHome}
        langContact={langContact}
      />
      <main>{children}</main>
    </IntlProvider>
  )
}

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
        menu {
          pt {
            to
            text
            key
            link
          }
          en {
            to
            text
            key
            link
          }
        }
      }
    }
  }
`

Layout.displayName = 'Layout'

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  inverseTheme: PropTypes.bool,
  location: PropTypes.object.isRequired
}

Layout.defaultProps = {
  inverseTheme: false
}

export default Layout
