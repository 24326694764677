import React, { useState, useMemo } from 'react'
import { Formik, Form, Field } from 'formik'
import { FieldCustom, FieldTextarea } from 'components/molecules'
import { Button, Spacer } from 'components/atoms'
import { useLocalStorage } from 'react-use'
import styled from 'styled-components'
import fetch from 'isomorphic-fetch'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'

const translation = {
  en: {
    message: {
      required: 'Please let us know how we can help!',
    },
    name: {
      required: 'Please enter your name.'
    },
    email: {
      required: 'Please enter your email address.',
      invalid: 'Hmm…this doesn’t seem to be a valid email address.',
    }
  },
  pt: {
    message: {
      required: 'Por favor, diga-nos como podemos ajudar!',
    },
    name: {
      required: 'Por favor, introduza o seu nome.'
    },
    email: {
      required: 'Por favor, introduza o seu endereço de e-mail.',
      invalid: 'Hmm...isto não parece ser um endereço de e-mail válido.',
    }
  }
}

const sendEmail = async values => {
  try {
    const response = await fetch(
      'https://caldeirasurfcamp.vercel.app/api/contact.js',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...values })
      })
    const json = await response.json()
    if (response.ok) {
      return json
    } else {
      throw new Error('error fetching request')
    }
  } catch (error) {
    throw new Error(error.message)
  }
}

const ContactForm = ({ onSuccess, onFailure, langKey }) => {
  const [initialValues, setInitialValues] = useLocalStorage('contact-form', {
    email: '',
    name: '',
    message: '',
  })

  const ContactSchema = useMemo(() => {
    const data = translation[langKey]
    return Yup.object().shape({
      name: Yup.string().required(data.name.required),
      email: Yup.string().email(data.email.invalid).required(data.email.required),
      message: Yup.string().required(data.message.required),
    })
  }, [langKey])

  const [hasSent, setHasSent] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [hasVerified, setHasVerified] = useState(false)
  const [hasTappedSubmit, setHasTappedSubmit] = useState(false)

  const buttonModifiers = useMemo(() => {
    const defaultModifiers = ['submit']
    if (hasSent) return [...defaultModifiers, 'disabled', 'lighter']
    if (hasFailed) return [...defaultModifiers, 'disabled', 'danger']
    return defaultModifiers
  }, [hasSent, hasFailed])

  const handleChange = values => {
    setInitialValues(values)
    setHasSent(false)
    setHasFailed(false)
  }

  const showCaptchaError = !hasVerified && hasTappedSubmit

  const handleChangeCaptcha = value => {
    setHasVerified(true)
  }

  return (
    <Formik
      validationSchema={ContactSchema}
      initialValues={initialValues}
      validate={handleChange}
      onSubmit={async (values, { setSubmitting }) => {
        setHasTappedSubmit(true)
        try {
          if (!hasVerified) return null
          if (!hasSent) await sendEmail(values)
          setHasFailed(false)
          setHasSent(true)
          setSubmitting(false)
          onSuccess(values)
        } catch (err) {
          setHasFailed(true)
          setHasSent(false)
          setSubmitting(false)
          onFailure(err, values)
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field
            name="email"
            title={<FormattedMessage id="form.email" />}
            component={FieldCustom}
          />
          <Field
            name="name"
            title={<FormattedMessage id="form.name" />}
            component={FieldCustom}
          />
          <Field
            name="message"
            title={<FormattedMessage id="form.message" />}
            component={FieldTextarea}
          />
          <Captcha>
            <ReCAPTCHA
              sitekey="6LeDNygUAAAAAHEF_ontOvZTMccXWUqYx_ZSk7PE"
              onChange={handleChangeCaptcha}
              onExpired={() => setHasVerified(false)}
              onErrored={() => setHasVerified(false)}
            />
          </Captcha>
          {showCaptchaError && (
            <Error>
              <FormattedMessage id="form.captcha" />
            </Error>
          )}
          <Submit top="m">
            <Button type="submit" modifiers={buttonModifiers} disabled={isSubmitting || hasSent || hasFailed} isLoading={isSubmitting}>
              {hasFailed && <FormattedMessage id="form.button.error" />}
              {hasSent && <FormattedMessage id="form.button.success" />}
              {!hasFailed && !hasSent && <FormattedMessage id="form.button.default" />}
            </Button>
          </Submit>
        </Form>
      )}
    </Formik>
  )
}

const Submit = styled.div`
  padding-top: ${ props => props.theme.spacing.m };
  width: 100%;
`

const Captcha = styled.div`
  overflow: auto;
  padding-bottom: ${ props => props.theme.spacing.xs };
`

const Error = styled.p`
  color: ${ props => props.theme.color.danger };
`

ContactForm.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

ContactForm.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
}

export default ContactForm
