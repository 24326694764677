import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link as LinkScroll } from 'react-scroll'
import { Link as LinkGatsby } from 'gatsby'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { lighten } from 'polished'

const MODIFIERS = {
  primary: ({ theme }) => `
    background-color: ${ theme.color.primary };
    color: ${ theme.color.baseInverse };
    &:hover {
      background-color: ${ lighten(0.1, theme.color.primary) };
      color: ${ theme.color.baseInverse };
    }
    &.active {
      color: ${ theme.color.baseInverse };
    }
  `,
  mobile: ({ theme }) => `
    padding-top: ${ theme.spacing.s };
    padding-bottom: ${ theme.spacing.s };
    font-size: ${ theme.size.s };
  `
}

const NavItem = ({ children, isHome, link, ...props }) => {
  if (isHome) {
    return (
      <Scroll
        {...props}
        spy={true}
        smooth={true}
        offset={-60}
        duration={2000}
        activeClass="active"
        tabIndex="0"
      >
        {children}
      </Scroll>
    )
  }

  return (
    <Link
      {...props}
      to={link}
    >
      {children}
    </Link>
  )
}

const linkCss = css`
  letter-spacing: 1.3px;
  padding: ${ props => props.theme.spacing.xs };
  border-radius: ${ props => props.theme.value.borderRadius };
  transition: all 0.1s ease-out;
  cursor: pointer;
  text-decoration: none;
  color: ${ props => props.theme.color.base };
  &:hover {
    background-color: ${ props => props.theme.color.bg };
    color: ${ props => props.theme.color.primary };
  }
  &:focus {
    background-color: ${ props => props.theme.color.bg };
    color: ${ props => props.theme.color.primary };
    outline: none;
  }
  &.active {
    color: ${ props => props.theme.color.primary };
  }
  ${ applyStyleModifiers(MODIFIERS) };
`

const Link = styled(LinkGatsby)`
  ${ linkCss }
`
const Scroll = styled(LinkScroll)`
  ${ linkCss }
`

NavItem.propTypes = {
  children: PropTypes.node.isRequired
}

NavItem.defaultProps = {
}

export default NavItem
