import React from "react";
import { Link } from "gatsby";
import {
  Container,
  Title,
  Button,
  Text,
  Content,
  Spacer,
} from "components/atoms";
import { FooterNav, Copyright } from "components/molecules";
import styled from "styled-components";
import { below } from "utils/breakpoints";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <path d="M14.97 2.703c3.998 0 4.468.018 6.048.09 1.461.064 2.251.308 2.78.516.701.273 1.2.594 1.723 1.117.523.523.85 1.022 1.117 1.723.202.528.451 1.318.516 2.78.072 1.58.09 2.05.09 6.047 0 3.998-.018 4.468-.09 6.048-.065 1.461-.308 2.251-.516 2.78a4.645 4.645 0 01-1.117 1.723 4.572 4.572 0 01-1.723 1.117c-.529.202-1.319.451-2.78.516-1.58.072-2.05.09-6.048.09s-4.467-.018-6.047-.09c-1.462-.065-2.252-.309-2.78-.516a4.645 4.645 0 01-1.723-1.117 4.572 4.572 0 01-1.117-1.723c-.202-.529-.452-1.319-.517-2.78-.071-1.58-.089-2.05-.089-6.048s.018-4.467.09-6.047c.064-1.462.308-2.252.516-2.78.273-.701.594-1.2 1.117-1.723a4.572 4.572 0 011.723-1.117c.528-.202 1.318-.452 2.78-.517 1.58-.077 2.05-.089 6.047-.089m0-2.697c-4.063 0-4.574.018-6.172.089-1.592.071-2.68.327-3.63.695a7.294 7.294 0 00-2.65 1.729A7.423 7.423 0 00.79 5.169c-.368.95-.624 2.037-.695 3.635-.071 1.592-.09 2.103-.09 6.166 0 4.064.019 4.575.09 6.173.071 1.592.327 2.679.695 3.635.38.986.897 1.818 1.729 2.65a7.423 7.423 0 002.65 1.728c.95.369 2.037.624 3.635.695 1.598.072 2.103.09 6.172.09 4.07 0 4.574-.018 6.173-.09 1.592-.07 2.679-.326 3.635-.695a7.294 7.294 0 002.65-1.728 7.423 7.423 0 001.728-2.65c.369-.95.624-2.037.695-3.635.072-1.598.09-2.103.09-6.173 0-4.069-.018-4.574-.09-6.172-.07-1.592-.326-2.68-.695-3.636a7.294 7.294 0 00-1.728-2.65 7.423 7.423 0 00-2.65-1.728C23.834.416 22.747.16 21.15.09 19.545.024 19.034.006 14.97.006z" />
    <path d="M14.97 7.283a7.688 7.688 0 000 15.374 7.692 7.692 0 007.687-7.687 7.692 7.692 0 00-7.687-7.687zm0 12.677a4.99 4.99 0 110-9.98 4.99 4.99 0 110 9.98z" />
    <circle cx="22.96" cy="6.98" r="1.794" />
  </svg>
);

const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
  >
    <g fill="none">
      <path
        fill="#000"
        d="M30 15c0-8.285-6.715-15-15-15S0 6.715 0 15c0 7.488 5.484 13.693 12.656 14.818V19.336H8.848V15h3.808v-3.305c0-3.758 2.239-5.836 5.666-5.836 1.64 0 3.358.293 3.358.293v3.692h-1.893c-1.863 0-2.443 1.157-2.443 2.344V15h4.16l-.665 4.336h-3.495v10.482C24.516 28.693 30 22.488 30 15z"
      />
      <path
        fill="#FFF"
        d="M20.839 19.336L21.504 15h-4.16v-2.813c0-1.186.58-2.343 2.443-2.343h1.893V6.152s-1.717-.293-3.358-.293c-3.427 0-5.666 2.078-5.666 5.836V15H8.848v4.336h3.808v10.482a15.11 15.11 0 004.688 0V19.336h3.495z"
      />
    </g>
  </svg>
);

const translation = {
  pt: {
    title: "Caldeira Guesthouse",
    subtitle:
      "Vem descobrir o encanto, as ondas e o sossego da Fajã da Caldeira de Santo Cristo",
    link: "/contact",
    nav: [
      {
        title: "Guesthouse",
        navItems: [
          {
            text: "Website",
            to: "/",
          },
          {
            text: "Booking",
            link:
              "https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.pt-pt.html",
          },
          {
            text: "Tripadvisor",
            link:
              "https://www.tripadvisor.com/Hotel_Review-g1178726-d9776050-Reviews-Caldeira_Guesthouse-Calheta_Madeira_Madeira_Islands.html",
          },
        ],
      },
      {
        title: "Redes-sociais",
        navItems: [
          {
            text: "Facebook",
            link:
              "https://www.facebook.com/Caldeira-Guesthouse-Surfcamp-501176026704572/",
          },
          {
            text: "Instagram",
            link: "https://www.instagram.com/caldeirasurfcamp/",
          },
        ],
      },
      {
        title: "Contactos",
        hasContacts: true,
        navItems: [
          {
            text: "Formulário online",
            to: "/contact",
          },
        ],
      },
      {
        title: "Localização",
        hasAddress: true,
      },
    ],
    license: "Licença: RRAL380",
    reclamation: "Livro de reclamações",
  },
  en: {
    title: "Caldeira Guesthouse",
    subtitle:
      "Discover the charm, the waves and the peacefulness of Fajã da Caldeira de Santo Cristo",
    link: "/en/contact",
    nav: [
      {
        title: "Guesthouse",
        navItems: [
          {
            text: "Website",
            to: "/",
          },
          {
            text: "Booking",
            link:
              "https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.pt-pt.html",
          },
          {
            text: "Tripadvisor",
            link:
              "https://www.tripadvisor.com/Hotel_Review-g1178726-d9776050-Reviews-Caldeira_Guesthouse-Calheta_Madeira_Madeira_Islands.html",
          },
        ],
      },
      {
        title: "Socials",
        navItems: [
          {
            text: "Facebook",
            link:
              "https://www.facebook.com/Caldeira-Guesthouse-Surfcamp-501176026704572/",
          },
          {
            text: "Instagram",
            link: "https://www.instagram.com/caldeirasurfcamp/",
          },
        ],
      },
      {
        title: "Contacts",
        hasContacts: true,
        navItems: [
          {
            text: "Contact form",
            to: "/contact",
          },
        ],
      },
      {
        title: "Location",
        hasAddress: true,
      },
    ],
    license: "License: RRAL380",
    reclamation: "Reclamation book",
  },
};

export const Footer = ({ langKey, showHeader }) => {
  const data = translation[langKey];
  return (
    <Container>
      {showHeader && (
        <Center>
          <Title modifiers={["weightMedium"]} withSeperator>
            {data.title}
          </Title>
          <Content>
            <Text>{data.subtitle}</Text>
          </Content>
          <Link to={data.link}>
            <Button>
              <FormattedMessage id="contact.short" />
            </Button>
          </Link>
        </Center>
      )}
      <Seperator>
        <Seperator.Line />
      </Seperator>
      <NavWrapper>
        {data.nav.map(({ title, navItems, hasContacts, hasAddress }) => (
          <FooterNav
            title={title}
            navItems={navItems}
            key={title}
            hasContacts={!!hasContacts}
            hasAddress={!!hasAddress}
          />
        ))}
      </NavWrapper>
      <Center>
        <Socials>
          <Socials.Link
            title="Facebook"
            href="https://www.facebook.com/Caldeira-Guesthouse-Surfcamp-501176026704572/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </Socials.Link>
          <Socials.Link
            title="Instagram"
            href="https://www.instagram.com/caldeirasurfcamp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </Socials.Link>
        </Socials>
        <Spacer top="m" bottom="l">
          <Copyright />
          <Spacer top="xxxs">
            <Text modifiers={["light", "xxs"]}>{data.license}</Text>
            <Text modifiers={["light", "xxs"]}>
              <a
                href={"https://www.livroreclamacoes.pt/Inicio/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.reclamation}
              </a>
            </Text>
          </Spacer>
        </Spacer>
      </Center>
    </Container>
  );
};

const Socials = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${(props) => props.theme.spacing.l};
`;

Socials.Link = styled.a`
  padding: ${(props) => props.theme.spacing.xs};
  cursor: pointer;
  transition: transform 0.2s ease;
  border-radius: ${(props) => props.theme.value.borderRadius};

  &:hover {
    background-color: ${(props) => props.theme.color.bg};
    color: ${(props) => props.theme.color.primary};
    transform: scale(1.1);
  }
  &:focus {
    background-color: ${(props) => props.theme.color.bg};
    color: ${(props) => props.theme.color.primary};
    outline: none;
  }
`;

const Center = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const Seperator = styled.div`
  max-width: 700px;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
`;
Seperator.Line = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.color.bgGrey};
  margin: ${(props) => props.theme.spacing.l} 0;
  border-radius: 8px;
`;

const NavWrapper = styled.nav`
  display: grid;
  flex-basis: 100%;
  flex-grow: 1;
  grid-gap: 2.5em;
  grid-template-columns: repeat(auto-fit, minmax(7em, max-content));
  padding-top: ${(props) => props.theme.spacing.l};
  justify-content: center;
  ${below.lg(
    (props) => `
    grid-template-columns: repeat(auto-fit, minmax(15em,max-content));
  `
  )}
`;

Footer.propTypes = {
  langKey: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
};

Footer.defaultProps = {
  showHeader: false,
};

export default Footer;
