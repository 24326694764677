import React, { useState, useMemo } from 'react'
import { Card, Icon, Title, Text, Spacer } from 'components/atoms'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'

const TestimonialCard = ({ message, name, link, type, theme }) => {
  const [isHover, setHover] = useState(false)
  const iconColor = useMemo(() => {
    return isHover ? theme.color.primaryLighter : theme.color.baseLighter
  }, [isHover])

  const handleMouseHover = entered => {
    setHover(entered)
  }

  return (
    <CardTestimonial onMouseEnter={() => handleMouseHover(true)} onMouseLeave={() => handleMouseHover(false)}>
      <CardTitle>
        <Spacer right="xs">
          <Icon icon="link" height={20} color={iconColor} />
        </Spacer>
        <Text modifiers={['sm', 'medium']}>
          {name}
        </Text>
      </CardTitle>
      <Text>
        {message}
      </Text>
    </CardTestimonial>
  )
}

const CardTestimonial = styled(Card)(props => css`
  cursor: pointer;
  &:hover {
    box-shadow: 0 8px 15px 0 rgba(0,0,0,0.05);
  }
`)

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${ props => props.theme.spacing.xs };
`

TestimonialCard.propTypes = {
  message: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.oneOf(['default', 'facebook']),
  theme: PropTypes.shape({}),
}

TestimonialCard.defaultProps = {
  name: 'John Doe',
  message: '',
  link: null,
}

export default withTheme(TestimonialCard)
