import styled, { css } from 'styled-components/macro'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { below } from 'utils/breakpoints'

const TEXT_MODIFIERS = {
  bold: ({ theme }) => `
    font-weight: ${ theme.weight.bold };
  `,
  medium: ({ theme }) => `
    font-weight: ${ theme.weight.medium };
  `,
  light: ({ theme }) => `
    color: ${ theme.color.baseLighter };
  `,
  inverse: ({ theme }) => `
    color: ${ theme.color.baseInverse };
  `,
  alternative: ({ theme }) => `
    font-family: ${ theme.font.alternative };
  `,
  sm: ({ theme }) => css`
    font-size: ${ theme.size.sm };
    ${ below.sm`
    font-size: ${ theme.size.ls };
    ` }
  `,
  xxs: ({ theme }) => css`
    font-size: ${ theme.size.xxs };
  `,
  xxxs: ({ theme }) => css`
    font-size: ${ theme.size.xxxs };
  `,
  ls: ({ theme }) => css`
    font-size: ${ theme.size.ls };
  `,
  italic: () => css`
    font-style: italic;
  `
}

const Text = styled.p`
  font-size: ${ props => props.theme.size.base };
  color: ${ props => props.theme.color.base };
  font-weight: ${ props => props.theme.weight.base };
  line-height: 1.8;
  ${ applyStyleModifiers(TEXT_MODIFIERS) };
`

export default Text
