export const generateKey = pre => {
  return `${ pre }_${ new Date().getTime() }`
}

const nPaths = url => url.split('/').filter(Boolean).length

export const isHomePage = (url, prefixDefault = true, langs) => {
  if (prefixDefault) {
    if (langs) {
      return nPaths(url) <= 1 && langs.includes(url.split('/').filter(Boolean)[0])
    } else {
      return nPaths(url) <= 1
    }
  } else {
    return nPaths(url) === 0 || (nPaths(url) === 1 && langs.includes(url.split('/').filter(Boolean)[0]))
  }
}
