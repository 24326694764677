import React, { useMemo, useRef } from 'react'
import styled, { css, withTheme } from 'styled-components'
import Slick from 'react-slick'
import { above, below } from 'utils/breakpoints'
import { useStaticQuery, graphql } from 'gatsby'
import { Icon } from 'components/atoms'
import Image from 'gatsby-image'
import { FormattedMessage } from 'react-intl'

const height = {
  large: '600px',
  small: '200px'
}

const SampleNextArrow = withTheme(props => {
  return (
    <ArrowNext
      role="button"
      onClick={props.onClick}
    >
      <Icon icon="arrow-next" color={props.theme.color.baseInverse} height={36} />
    </ArrowNext>
  )
})

const SamplePrevArrow = withTheme(props => {
  return (
    <ArrowPrev
      role="button"
      onClick={props.onClick}
    >
      <Icon icon="arrow-previous" color={props.theme.color.baseInverse} height={36} />
    </ArrowPrev>
  )
})
const ArrowPrev = styled.div`
  height: 100%;
  position: absolute;
  padding: ${ props => props.theme.spacing.xxs };
  ${ above.md(props => css`
    padding: ${ props.theme.spacing.ms };
  `) }
  top: 0;
  left: 0;
  z-index: ${ props => props.theme.zIndex.l };
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: background-image 0.2s ease;
  background-image: linear-gradient(270deg, rgba(216,216,216,0.00) 0%, rgba(0,0,0,0.2) 100%);
  opacity: 0.5;
  transition: opacity 0.2s ease;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const ArrowNext = styled.div`
  height: 100%;
  position: absolute;
  padding: ${ props => props.theme.spacing.xxs };
  ${ above.md(props => css`
    padding: ${ props.theme.spacing.ms };
  `) }
  top: 0;
  left: 100%;
  transform: translateX(-100%);
  z-index: ${ props => props.theme.zIndex.l };
  display: flex;
  align-items: center;
  transition: background-image 0.2s ease;
  background-image: linear-gradient(270deg, rgba(0,0,0,0.2) 0%, rgba(216,216,216,0.00) 100%);
  opacity: 0.5;
  transition: opacity 0.2s ease;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const Line = styled.div`
  width: 84px;
  height: 2px;
  background-color: ${ props => props.theme.color.bg };
  ${ below.md(props => css`
    background-color: ${ props => props.theme.color.bgInverse };
    width: 8px;
    height: 8px;
  `) }
`

const LineWrapper = styled.div`
  padding: ${ props => props.theme.spacing.xxs };
  padding-bottom: ${ props => props.theme.spacing.l };
  cursor: pointer;
  border-radius: 8px;
  ${ below.md(props => css`
    padding: ${ props => props.theme.spacing.xxxs };
  `) }
`

const Dot = ({ index, onClick }) => {
  return (
    <LineWrapper onClick={onClick} role="button">
      <Line />
    </LineWrapper>
  )
}

const DotWrapper = styled.ul`
  display: flex;
  position: absolute;
  top: 100%;
  width: 100%;
  transform: translateY(-100%);
  justify-content: center;
  li {
    display: inline;
    opacity: 0.5;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
  }
    &.slick-active {
      opacity: 1;
    }
  }
  ${ below.md(props => css`
    transform: translateY(0%);
    justify-content: start;
    padding: ${ props => props.theme.spacing.xxs } ${ props => props.theme.spacing.ms };
  `) }
`

const query = graphql`
  query {
    camp: file(relativePath: { eq: "camp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    campOutside: file(relativePath: { eq: "outside-pranchas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomSuite: file(relativePath: { eq: "quarto-suite.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomBelo: file(relativePath: { eq: "quarto-belo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomGraciosa: file(relativePath: { eq: "quarto-graciosa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    table: file(relativePath: { eq: "mesa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    roomSanguinhal: file(relativePath: { eq: "quarto-sanguinhal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    view: file(relativePath: { eq: "view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    passadico: file(relativePath: { eq: "passadico.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Slider = () => {
  const sliderRef = useRef(null)
  const imageData = useStaticQuery(query)

  const slides = useMemo(() => {
    if (imageData) {
      return [
        {
          fluid: imageData.camp.childImageSharp.fluid,
          subtitle: 'Caldeira Gueshouse & Surfcamp',
          stretch: true,
        },
        {
          fluid: imageData.roomSuite.childImageSharp.fluid,
          subtitle: 'Suite'
        },
        {
          fluid: imageData.roomBelo.childImageSharp.fluid,
          subtitle: <FormattedMessage id="room" values={{ name: 'Belo' }} />
        },
        {
          fluid: imageData.roomGraciosa.childImageSharp.fluid,
          subtitle: <FormattedMessage id="room" values={{ name: 'Graciosa' }} />
        },
        {
          fluid: imageData.table.childImageSharp.fluid,
        },
        {
          fluid: imageData.roomSanguinhal.childImageSharp.fluid,
          subtitle: <FormattedMessage id="room" values={{ name: 'Sanguinhal' }} />
        },
        {
          fluid: imageData.view.childImageSharp.fluid,
          subtitle: <FormattedMessage id="view" />
        },
        {
          fluid: imageData.passadico.childImageSharp.fluid,
          subtitle: <FormattedMessage id="lagoon" />
        },
      ]
    }
    return []
  }, [imageData])

  return (
    <Wrapper>
      <Slick
        ref={sliderRef}
        slidesToShow={1}
        slidesToScroll={1}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        appendDots={dots => <DotWrapper>{dots}</DotWrapper>}
        customPaging={i => <Dot index={i} />}
        fade
        infinite
        dots
      >
        {slides.map(slide => (
          <Slide key={slide.subtitle} stretch={slide.stretch}>
            {slide.subtitle && (
              <Subtitle>
                {slide.subtitle}
              </Subtitle>
            )}
            <Image
              fluid={slide.fluid}
            />
          </Slide>
        )
        )}
      </Slick>
    </Wrapper>
  )
}

const Subtitle = styled.div`
  padding: ${ props => props.theme.spacing.xxs } ${ props => props.theme.spacing.xxxs };
  font-family: ${ props => props.theme.font.alternative };
  font-size: ${ props => props.theme.size.xxxs };
  background-color: ${ props => props.theme.color.bgLighter };
  position: absolute;
  top: 70%;
  right: 10%;
  z-index: ${ props => props.theme.zIndex.xl };

  ${ above.md(props => css`
    top: 80%;
    right: 10%;
    font-size: ${ props => props.theme.size.sm };
    padding: ${ props => props.theme.spacing.xs } ${ props => props.theme.spacing.xxs };
  `) }
`

const Slide = styled.div(props => css`
  overflow: hidden;
  width: 100%;
  background-color: ${ props.theme.color.bg };
  position: relative;
  img {
    height: ${ height.small } !important;
    width: 100%  !important;
    object-fit: contain  !important;
    object-position: center center !important;
  }

  height: ${ height.small };
  ${ above.md(props => css`
    height: ${ height.large };
    img {
      height: ${ height.large }  !important;
      width: ${ props.stretch ? '100%' : 'auto' }  !important;
      object-fit: ${ props.stretch ? 'cover' : 'inherit' }  !important;
      object-position: inherit  !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  `) }
`)

const Wrapper = styled.div`
  width: 100%;

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden
  }

  .slick-slider {
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
  }

  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
  }

  .slick-list:focus {
    outline: 0
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand
  }

  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  .slick-track {
    top: 0;
    left: 0
  }

  .slick-track:after,
  .slick-track:before {
    display: table;
    content: ''
  }

  .slick-track:after {
    clear: both
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
  }

  [dir=rtl] .slick-slide {
    float: right
  }

  .slick-slide img {
    display: block
  }

  .slick-slide.slick-loading img {
    display: none
  }

  .slick-slide.dragging img {
    pointer-events: none
  }

  .slick-initialized .slick-slide {
    display: block
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
  }

  .slick-arrow.slick-hidden {
    display: none
  }
`

export default Slider
