import React, { useMemo } from 'react'
import styled, { withTheme } from 'styled-components/macro'
import PropTypes from 'prop-types'
import { themeType } from 'types'
import { above } from 'utils/breakpoints'

export const spacing = {
  base: 'base',
  xxxs: 'xxxs',
  xxs: 'xxs',
  xxms: 'xxms',
  xs: 'xs',
  xms: 'xms',
  s: 'sm',
  sm: 'sm',
  ms: 'ms',
  m: 'm',
  l: 'l',
  xl: 'xl',
  xxl: 'xxl',
  xxxl: 'xxxl'
}

const spacingTypes = Object.keys(spacing)

const Spacer = ({
  theme,
  top,
  bottom,
  left,
  right,
  spacing,
  useMargin,
  children,
  debugMode,
  isDesktop
}) => {
  const spacingStyles = useMemo(() => {
    // get value from theme depending on key : eg: 'xl'
    const computeSpacing = key => {
      // if the key is false or 0 the component will remove spacing
      if (key === false || key === 0) return 0

      const themeSpacing = theme.spacing[key]
      if (!themeSpacing) {
        // eslint-disable-next-line no-console
        console.error(`Invalid spacing prop: ${ key }`)
        return theme.spacing[spacing.m]
      }
      return themeSpacing
    }

    const topSpacing = top || (spacing || false)
    const rightSpacing = right || (spacing || false)
    const bottomSpacing = bottom || (spacing || false)
    const leftSpacing = left || (spacing || false)

    const computedSpacing = `${ computeSpacing(topSpacing) } ${ computeSpacing(
      rightSpacing
    ) } ${ computeSpacing(bottomSpacing) } ${ computeSpacing(leftSpacing) }`

    return computedSpacing
  }, [theme.spacing, top, right, bottom, left, spacing])

  return (
    <SpacingStyles
      space={spacingStyles}
      useMargin={useMargin}
      addBorder={debugMode}
      isDesktop={isDesktop}
    >
      {children}
    </SpacingStyles>
  )
}

const SpacingStyles = styled.div`
  border: ${ props => (props.addBorder ? '1px solid red' : 0) };

  ${ props =>
    props.useMargin
      ? props.isDesktop ? above.md`margin: ${ props.space }` : `margin: ${ props.space };`
      : props.isDesktop ? above.md`padding: ${ props.space }` : `padding: ${ props.space };`
}
`

const spacingPropType = PropTypes.oneOfType([
  PropTypes.oneOf(spacingTypes),
  PropTypes.bool
])

Spacer.propTypes = {
  theme: themeType.isRequired,
  spacing: spacingPropType,
  top: spacingPropType,
  right: spacingPropType,
  bottom: spacingPropType,
  left: spacingPropType,
  useMargin: PropTypes.bool,
  debugMode: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isDesktop: PropTypes.bool,
}

Spacer.defaultProps = {
  spacing: false,
  top: null,
  right: null,
  bottom: null,
  left: null,
  useMargin: false,
  debugMode: false,
  isDesktop: false,
}

export default withTheme(Spacer)
