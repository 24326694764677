import React from 'react'
import {
  WidgetBooking,
  WidgetGoogle,
  WidgetFacebook,
  WidgetCovid
} from 'components/molecules'
import { Container, SeperatorVertical } from 'components/atoms'
import styled from 'styled-components'
import { useBreakpoints } from 'hooks'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

const data = {
  booking: {
    rating: 9.6,
    comments: 155,
  },
  google: {
    rating: 4.9,
    comments: 82,
  },
  facebook: {
    rating: 4.8,
    comments: 82,
  },
}

const Social = ({ clearContainer }) => {
  const { md } = useBreakpoints()
  const { booking, google, facebook } = data

  return (
    <Container modifiers={['fixNav', md ? 'horizontal' : 'leftOnly']}>
      <Section>
        <WidgetCovid />
        <SeperatorVertical />
        <WidgetBooking
          semanticText={<FormattedMessage id="superb" />}
          numberOfComments={booking.comments}
          score={booking.rating}
        />
        <SeperatorVertical />
        <WidgetGoogle numberOfReviews={google.comments} stars={google.rating} />
        <SeperatorVertical />
        <WidgetFacebook numberOfReviews={facebook.comments} stars={facebook.rating} />
      </Section>
    </Container>
  )
}

const Section = styled.section`
  display: flex;
  align-items: center;
  height: 80px;
  min-width: 320px;
  overflow-x: auto;
`

Social.propTypes = {
  clearContainer: PropTypes.bool,
}

Social.defaultProps = {
  clearContainer: false,
}

export default Social
