import React, { useMemo } from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { animated, useSpring } from 'react-spring'
import { themeType } from 'types'

const Seperator = withTheme(({ repeat, theme }) => {
  const numberOfRepeats = useMemo(() => {
    const repeatsArr = []
    for (let i = 0; i < repeat; i++) {
      repeatsArr.push(i)
    }
    return repeatsArr
  }, [repeat])
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 }
  })

  return (
    <Wrapper style={props}>
      {numberOfRepeats.map(index => (
        <Fix key={index}>
          <svg
            width="60"
            height="5"
            viewBox="0 0 60 5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1c3.593 2.196 7.985 3.294 13.176 3.294C20.962 4.294 21.56 1 26.352 1s7.486 3.294 17.069 3.294c6.388 0 11.678-1.098 15.87-3.294"
              stroke={theme.color.primary}
              fill="none"
              fillRule="evenodd"
            />
          </svg>
        </Fix>
      ))}
    </Wrapper>
  )
})

Seperator.propTypes = {
  repeat: PropTypes.number,
  theme: themeType
}

Seperator.defaultProps = {
  repeat: 3
}

const Fix = styled.div`
  margin-left: -1px;
  display: inline;
`

const Wrapper = styled(animated.div)`
  margin-bottom: ${ props => props.theme.spacing.xxms };
  overflow: hidden;
`

export default Seperator
