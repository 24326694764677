import React from 'react'
import styled, { withTheme } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { above, below } from 'utils/breakpoints'
import {
  Container,
  Title,
  Spacer,
  Text,
  Icon,
  Reveal
} from 'components/atoms'
import { useBreakpoints } from 'hooks'

const dataTranslations = {
  en: {
    title: `Nature, simplicity, tranquility.`,
    subtitle: `Discover the charm, the waves and the peacefulness of Fajã da Caldeira de Santo Cristo`,
    location: `São Jorge, Azores`
  },
  pt: {
    title: `Natureza, simplicidade e muita tranquilidade.`,
    subtitle: `Descubra o encanto, as ondas e o sossego da Fajã da Caldeira de Santo Cristo`,
    location: `São Jorge, Azores`
  },
}

const HeroContainer = ({ langKey }) => {
  const { sm } = useBreakpoints()
  let data = dataTranslations[langKey]

  return (
    <Container modifiers={['fixBottom', 'smallTop']}>
      <Hero>
        <Hero.Content>
          <Spacer bottom="xs">
            <Reveal effect="fadeInBottom">
              <Title modifiers={['m', 'inverse']} withSeperator>
                {data.title}
              </Title>
            </Reveal>
          </Spacer>
          <Subtitle>
            <Text modifiers={['inverse', 'alternative']}>
              {data.subtitle}
            </Text>
          </Subtitle>
          <Location>
            <Icon icon="location" color="#B8E986" />
            <Text modifiers={['inverse']}>{data.location}</Text>
          </Location>
          <Hero.SpacingBottomFix />
        </Hero.Content>
        <Hero.Background>
          <Image />
        </Hero.Background>
      </Hero>
    </Container>
  )
}

const Hero = styled.div`
  height: calc(100vh - ${ props => props.theme.semantic.nav.heightFixMobile });
  min-height: 400px;
  width: 100%;
  padding-bottom: ${ props => props.theme.spacing.ms };
  ${ above.md`
    padding-bottom: ${ props => props.theme.spacing.l };
    height: calc(100vh - ${ props => props.theme.semantic.nav.heightFix });
  ` };
  position: relative;
`

Hero.Content = styled.div`
  padding: ${ props => props.theme.spacing.l };
  position: absolute !important;
  z-index: ${ props => props.theme.zIndex.l };
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 830px;
  ${ below.sm`
    h2 {
      font-size: ${ props => props.theme.size.sm };
    }
    padding: ${ props => props.theme.spacing.ms };
    justify-content: center;
  ` };
`

Hero.SpacingBottomFix = styled.div`
  width: 100%;
  height: ${ props => props.theme.spacing.ms };
`

Hero.Background = styled.div`
  width: 100%;
  height: 100%;
`

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${ props => props.theme.spacing.m };
  ${ above.md`
  padding-bottom: ${ props => props.theme.spacing.xxl };
  ` };
  .subtitle-icon {
    padding-right: ${ props => props.theme.spacing.xs };
  }
`

const Location = styled.div`
  display: flex;
  align-items: center;
`

const Image = withTheme(({ theme }) => (
  <StaticQuery
    query={imageQuery}
    render={data => (
      <Img
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{
          height: '100%',
          width: '100%',
          borderRadius: theme.value.borderRadius
        }}
      />
    )}
  />
))

const imageQuery = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "caldeira-header.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default HeroContainer
