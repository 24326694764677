import React from 'react'
import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'

export const FieldCustom = ({
  field, // { name, value, onChange, onBlur }
  form: {
    touched, errors, isValid, isSubmitting,
  },
  title,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name]
  return (
    <div>
      <Label>{title}</Label>
      <Input type="text" modifiers={hasError ? 'error' : ''} {...field} {...props} />
      {hasError && (
        <Error>{errors[field.name]}</Error>
      )}
    </div>
  )
}

export const FieldTextarea = ({
  field, // { name, value, onChange, onBlur }
  form: {
    touched, errors, isValid, isSubmitting,
  },
  title,
  ...props
}) => {
  const hasError = touched[field.name] && errors[field.name]
  return (
    <div>
      <Label>{title}</Label>
      <Textarea
        type="text"
        rows="5"
        modifiers={hasError ? 'error' : ''}
        {...field}
        {...props}
      />
      {hasError && (
        <Error>{errors[field.name]}</Error>
      )}
    </div>
  )
}

const FIELD_MODIFIERS = {
  error: ({ theme }) => `
    border-color:${ theme.color.danger };
  `,
}

const Input = styled.input`
  color: ${ props => props.theme.color.base };
  padding: 1.15rem 1rem;
  font-size: ${ props => props.theme.size.base };
  width: 100%;
  border-radius: ${ props => props.theme.value.borderRadius };
  margin-bottom: ${ props => props.theme.spacing.xxs };
  &::placeholder {
    color: ${ props => props.theme.color.baseLighter };
  }
  transition: border 0.2s ease;
  border: 1px solid ${ props => props.theme.color.borderDarker };
  &:focus {
    outline: 0;
    border: 1px solid ${ props => props.theme.color.primary };
  }
  ${ applyStyleModifiers(FIELD_MODIFIERS) };
`

const Textarea = styled.textarea`
  color: ${ props => props.theme.color.base };
  padding: 1.15rem 1rem;
  font-size: ${ props => props.theme.size.base };
  width: 100%;
  border-radius: ${ props => props.theme.value.borderRadius };
  margin-bottom: ${ props => props.theme.spacing.xxs };
  height: auto;
  resize: vertical;
  line-height: 1.5;
  &::placeholder {
    color: ${ props => props.theme.color.baseLighter };
  }
  transition: border 0.2s ease;
  border: 1px solid ${ props => props.theme.color.borderDarker };
  &:focus {
    outline: 0;
    border: 1px solid ${ props => props.theme.color.primary };
  }
  ${ applyStyleModifiers(FIELD_MODIFIERS) };
`

const Label = styled.label`
  display: inline-block;
  margin: ${ props => props.theme.spacing.xxs } 0;
  font-weight: ${ props => props.theme.weight.bold };
`

const Error = styled.p`
  color: ${ props => props.theme.color.danger };
`
