import React from 'react'
import {
  Container,
  Content,
  Title,
  Text,
  Spacer,
  Reveal
} from 'components/atoms'
import { GoogleMap } from 'components/molecules'
import styled, { css } from 'styled-components'
import { below } from 'utils/breakpoints'
import MapIllustration from 'images/caldeira.svg'
import { Element } from 'react-scroll'
import { graphql, useStaticQuery } from 'gatsby'

const translation = {
  pt: {
    title: 'Localização',
    summary: 'Esta pequena planície junto ao mar, possivelmente uma das mais bonitas e sem dúvida a mais famosa fajã da ilha de São Jorge foi, classificada como Reserva Natural',
    rest: 'pelo Governo Regional dos Açores especialmente por causa da existência de amêijoas na sua lagoa denominada Lagoa da Fajã de Santo Cristo.A Fajã da Caldeira de Santo Cristo é uma fajã localizada na freguesia da Ribeira Seca, concelho da Calheta, ilha de São Jorge, arquipélago dos Açores. É considerada um santuário do surf, sendo procurada por praticantes da modalidade vindos de todo o mundo.',
  },
  en: {
    title: 'Location',
    summary: 'This small plateau by the ocean – arguably one of the most beautiful and definitely the most famous fajã on São Jorge Island – is considered a Nature Reserve',
    rest: 'by the Regional Government of the Azores, especially due to the clams existing in its lake, the so-called Lagoa da Fajã de Santo Cristo. The fajã is located in the civil parish of Ribeira Seca, municipality of Calheta, São Jorge Island, Azores archipelago. It is deemed a surf sanctuary and it is sought-after by surfers from all over the world.',
  }
}

const Location = ({ langKey }) => {
  const data = translation[langKey]

  const staticData = useStaticQuery(graphql`
    query Address {
      site {
        siteMetadata {
          address
        }
      }
    }
  `)

  const address = staticData.site.siteMetadata.adress

  return (
    <Element name="location">
      <Container modifiers={['fixLeft']}>
        <Reveal effect="fadeInBottom">
          <Align>
            <Title modifiers={['ml', 'weightMedium', 'right']} withSeperator>
              {data.title}
            </Title>
          </Align>
        </Reveal>
        <Wrapper>
          <Wrapper.Start>
            <div className="illustration">
              <MapIllustration />
            </div>
          </Wrapper.Start>
          <Wrapper.End>
            <Content>
              <Text modifiers={['sm', 'medium']}>{data.summary}</Text>
              <Text>
                {data.rest}
              </Text>
              <Spacer top="m">
                <GoogleMap />
              </Spacer>
              <Spacer top="xxs">
                <Text modifiers={['xxs', 'light']}>
                  {address}
                </Text>
              </Spacer>
            </Content>
          </Wrapper.End>
        </Wrapper>
      </Container>
    </Element>
  )
}

const Align = styled.div`
  text-align: right;
  ${ below.md(props => css`
    text-align: left;
  `) }

  padding: ${ props => props.theme.spacing.ms };
  ${ below.md(props => css`
    padding-bottom: 0;
  `) }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  ${ below.md(props => css`
    justify-content:
    flex-direction: row;
  `) }
`

Wrapper.Start = styled.div`
  height: 450px;
  width: 247px;
  position: relative;
  transform: translateY(-20%);
  ${ below.md(props => css`
    transform: translateY(-10%);
    position: absolute;
    opacity: 0.06;
  `) }
  .illustration {
    position: absolute;
  }
`

Wrapper.End = styled.div`
  max-width: 1060px;
  z-index: 50;
  padding-left: 4rem;
  ${ Text } {
    text-align: right;
  }
  ${ below.md(props => css`
    padding-left: 0;
    ${ Text } {
      text-align: left;
    }
  `) }
`

export default Location
