import styled from 'styled-components'

const SeperatorVertical = styled.div`
  height: ${ props => props.theme.size.s };
  border: 1px solid ${ props => props.theme.color.border };
  width: 1px;
  margin-left: ${ props => props.theme.spacing.xs };
  margin-right: ${ props => props.theme.spacing.xs };
  display: inline;
`

export default SeperatorVertical
