import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { below } from 'utils/breakpoints'

const query = graphql`
  query {
    covid: file(relativePath: { eq: "covid.png" }) {
      childImageSharp {
        fluid(maxHeight: 100, quality: 100) {
          src
        }
      }
    }
  }
`

const WidgetCovid = () => {
  const data = useStaticQuery(query)
  return (
    <Widget
      href="https://clean-safe.azores.gov.pt/"
      title="Clean & Safe Açores"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Widget.Logo>
        <img
          alt="Safe and secure certification"
          src={data.covid.childImageSharp.fluid.src}
        />
      </Widget.Logo>
      <Widget.Info>
        Establishment complying with Health Measures
        {' '}
        <Widget.Bold>Portugal</Widget.Bold>
      </Widget.Info>
    </Widget>
  )
}

const Widget = styled.a`
  display: inline-flex;
  padding: ${ props => props.theme.spacing.xxms };
  align-items: center;
  transition: background-color 0.1s ease-out;
  border-radius: ${ props => props.theme.value.borderRadius };

  text-decoration: none;
  color: ${ props => props.theme.color.base };

  &:hover {
    background-color: ${ props => props.theme.color.bg };
    cursor: pointer;
  }
`

Widget.Logo = styled.div`
  transition: opacity 0.2s ease;
  opacity: 1;
  height: 36px;
  display: flex;
  align-items: center;
  img {
    height: 40px;
  }
  &:hover {
    opacity: 0.9;
  }
`

Widget.Info = styled.div`
  padding-left: ${ props => props.theme.spacing.xs };
  color: ${ props => props.theme.color.baseLigther };
  font-size: ${ props => props.theme.size.xxxs };
  max-width: 154px;

  ${ below.md`
    width: 200px;
  ` }
`

Widget.Bold = styled.span`
  font-weight: ${ props => props.theme.weight.bold };
`

export default WidgetCovid
