import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ButtonLine = ({ children, onClick }) => {
  return (
    <Button onClick={onClick}>
      <Button.Start>
        <Button.Line className="button--line" />
      </Button.Start>
      {children}
    </Button>
  )
}

const Button = styled.button(props => css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: ${ props.theme.spacing.xms };
  font-family: ${ props.theme.font.alternative };
  font-size: ${ props.theme.size.ls };
  display: flex;
  align-items: center;
  font-weight: ${ props.theme.weight.base };
  border-radius: ${ props.theme.value.borderRadius };

  &:hover {
    .button--line {
      width: 100%;
    }
  }
  &:focus {
    background-color: ${ props.theme.color.bg };
    outline: none;
  }
`)

Button.Start = styled.div(props => css`
  height: 1px;
  width: 80px;
  margin-right: ${ props.theme.spacing.xxms };
`)

Button.Line = styled.div(props => css`
  height: 2px;
  width: 70px;
  border-radius: 2px;
  background-color: ${ props.theme.color.primaryLighter };
  transition: width 120ms;
`)

ButtonLine.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}

ButtonLine.defaultProps = {
  onClick: () => null
}

export default ButtonLine
