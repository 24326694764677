import styled, { css } from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { below } from 'utils/breakpoints'

const CONTENT_MODIFIERS = {
  fixTop: () => css`
    padding-top: 0;
  `,
  fixRight: () => css`
    padding-right: 0;
  `,
  fixHorizontal: () => css`
    padding-right: 0 !important;
    padding-left: 0 !important;
  `
}

const Content = styled.div`
  padding: ${ props => props.theme.spacing.ms };
  ${below.md(props => css`
    padding-right: ${props.theme.spacing.s};
    padding-left: ${props.theme.spacing.s};
  `)}
  p {
    padding-bottom: ${ props => props.theme.spacing.xs };
  }
  ${ applyStyleModifiers(CONTENT_MODIFIERS) };
`

export default Content