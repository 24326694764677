/*
  Use like:
  const lang = useLang(location)
*/
import { useStaticQuery, graphql } from 'gatsby'
import { getCurrentLangKey } from 'ptz-i18n'

const query = graphql`
  query lang {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`

const useLang = location => {
  const data = useStaticQuery(query)
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const url = location.pathname
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)

  return { langKey }
}

export default useLang
