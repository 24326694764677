import React, { useState, useRef, memo } from 'react'
import styled, { withTheme, css } from 'styled-components'
import { Icon } from 'components/atoms'
import { below } from 'utils/breakpoints'

const StoriesCarousel = memo(function StoriesCarousel (props) {
  const { images } = props

  /* define base variable */
  const nextMsec = props.nextMsec || 5000
  const barHeight = props.barHeight || 1.5
  const backgroundSize = props.backgroundSize || 'cover'
  const backgroundColor = props.backgroundColor || '#202322'

  const [position, setPosition] = useState(0)
  const [isTransition, setIsTransition] = useState(true)
  const [isReset, setIsReset] = useState(true)
  const [isPaused, setIsPaused] = useState(false)
  const timeout = useRef([])

  /* common functions */
  function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  function noneTransition (callback) {
    return new Promise(async resolve => {
      setIsTransition(false)
      await sleep(20)
      callback()
      await sleep(20)
      setIsTransition(true)
      await sleep(20)
      resolve()
    })
  }

  async function startCarousel (p) {
    if (timeout.current.length > 0) {
      timeout.current.forEach(n => clearTimeout(n))
      timeout.current = []
    }

    setIsReset(false)
    setPosition(p)

    if (isPaused) return null

    timeout.current.push(
      setTimeout(async () => {
        if (p === images.length - 1) {
          await sleep(2000)
          await noneTransition(() => setIsReset(true))
          startCarousel(0)
        } else {
          startCarousel(p + 1)
        }
      }, nextMsec)
    )
  }

  async function skip (w) {
    let afterPosition = position + 1
    const beforePosition = position - 1
    const isLast = position === images.length - 1
    const isFirst = position === 0
    if (w === 'next') {
      afterPosition = isLast ? 0 : afterPosition
    }

    if (w === 'before') {
      afterPosition = isFirst ? 0 : beforePosition
    }

    await noneTransition(async () => {
      setIsReset(true)
      await sleep(20)
      startCarousel(afterPosition)
    })
  }

  /* when flip */
  const coordX = useRef(0)
  function ontouchstart (e) {
    const touches = e.changedTouches[0]
    coordX.current = touches.pageX
  }
  function ontouchend (e) {
    const touches = e.changedTouches[0]
    const diff = touches.pageX - coordX.current
    if (Math.abs(diff) > 100) {
      skip(Math.sign(diff) > -1 ? 'before' : 'next')
    }
  }

  if (images.length > 0 && images.length < 2) {
    return (
      <Styles>
        <div
          className="reactInstagramCarousel"
          onTouchStart={ontouchstart}
          onTouchEnd={ontouchend}>
          {images.map((src, index) => (
            <div
              className="reactInstagramCarousel__image"
              style={{
                backgroundImage: `url(${ src })`,
                backgroundSize,
                backgroundColor,
                opacity: 1
              }}
              key={`${ src }-${ index }`}
            />
          ))}
        </div>
      </Styles>
    )
  }

  return (
    <Styles>
      <div
        className="reactInstagramCarousel"
        onTouchStart={ontouchstart}
        onTouchEnd={ontouchend}>
        {images.map((src, index) => (
          <div
            className="reactInstagramCarousel__image"
            style={{
              backgroundImage: `url(${ src })`,
              backgroundSize,
              backgroundColor,
              opacity: isReset
                ? index === 0
                  ? 1
                  : 0
                : index === position
                  ? 1
                  : 0
            }}
            key={`${ src }-${ index }`}
          />
        ))}

        <div
          className="reactInstagramCarousel__skip--left"
          onClick={() => skip('before')}
        >
          <Icon icon="arrow-previous" color={props.theme.color.baseInverse} height={24} />
        </div>
        <div
          className="reactInstagramCarousel__skip--right"
          onClick={() => skip('next')}
        >
          <Icon icon="arrow-next" color={props.theme.color.baseInverse} height={24} />
        </div>

        <div className="reactInstagramCarousel__progressBarSpace">
          {images.map((_, k) => (
            <div
              className="reactInstagramCarousel__progressBar"
              style={{
                width: `calc(100% / ${ images.length } - 6%)`,
                height: `${ barHeight }px`
              }}
              key={k}>
              <div
                className="reactInstagramCarousel__progressBar--load"
                style={{
                  width: isReset ? '0' : k <= position ? '100%' : '0',
                  transition: isTransition
                    ? k === position
                      ? `${ nextMsec }ms linear`
                      : '0s'
                    : '0s'
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Styles>
  )
})

const Styles = styled.div`
  width: 100%;
  height: 500px;

  ${ below.md(props => css`
    height: 300px;
  `) }

  .reactInstagramCarousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex; }
  .reactInstagramCarousel__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center; }
  .reactInstagramCarousel .reactInstagramCarousel__skip--left, .reactInstagramCarousel .reactInstagramCarousel__skip--right {
    position: relative;
    width: 50%;
    height: 100%;
    }
    .reactInstagramCarousel__skip--left {
      display: flex;
      align-items: center;
      opacity: 0.5;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .reactInstagramCarousel__skip--right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      opacity: 0.5;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  .reactInstagramCarousel__progressBarSpace {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .reactInstagramCarousel__progressBar {
    position: relative;
    width: auto;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 2%;
    overflow: hidden; }
    .reactInstagramCarousel__progressBar--load {
      position: relative;
      width: 0;
      height: 100%;
      background-color: white; }
`

export default withTheme(StoriesCarousel)
