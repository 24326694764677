import React from 'react'
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Icon } from 'components/atoms'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { modifiersType } from 'types'

const ITEM_MODIFIERS = {
  base: ({ theme }) => `
    color: ${ theme.color.base };
    a {
      color: ${ theme.color.base };
    }
  `,
  smallWidth: () => `
    max-width: 210px;
  `,
  weightBase: ({ theme }) => `
    font-weight: ${ theme.weight.base } !important;
  `
}

const FooterNavItem = ({ link, to, children, icon, modifiers }) => {
  const isDefault = !link && !to
  const isExternalLink = !!link
  const isInternalLink = !!to

  return (
    <Li modifiers={modifiers}>
      {isDefault && <>{children}</>}
      {isExternalLink ? (
        <a href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {icon && (
            <div className="footer-icon">
              <Icon icon={icon} height={20} />
            </div>
          )}
          {children}
        </a>
      ) : null}
      {isInternalLink ? ((
        <Link to={to}>
          {children}
        </Link>
      )) : null}
    </Li>
  )
}

FooterNavItem.propTypes = {
  link: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  modifiers: modifiersType,
}

FooterNavItem.defaultProps = {
  link: null,
  to: null,
  isContact: false,
  icon: null,
  modifiers: []
}

const FooterNav = ({ title, navItems, hasContacts, hasAddress }) => {
  const data = useStaticQuery(query)
  const { site: { siteMetadata: { contact, address } } } = data
  return (
    <Wrapper>
      {title && <h3>{title}</h3>}
      <ul>
        {hasAddress && (
          <FooterNavItem
            link='https://www.google.com/maps/place/Caldeira+SurfCamp+%26+Guesthouse/@38.622221,-27.925691,15z/data=!4m8!3m7!1s0x0:0xcf9124e6e6396a17!5m2!4m1!1i2!8m2!3d38.622221!4d-27.925691'
            key='location'
            modifiers={['base', 'smallWidth', 'weightBase']}
          >
            {address}
          </FooterNavItem>
        )}
        {navItems.map(({ text, to, link, ...rest }) => (
          <FooterNavItem to={to} link={link} key={text} {...rest}>
            {text}
          </FooterNavItem>
        ))}
        {hasContacts && contact.map(({ link, type, value }) => (
          <FooterNavItem
            link={link}
            key={value}
            icon={type}
            modifiers={['base']}
          >
            {value}
          </FooterNavItem>
        ))}
       
      </ul>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  h3 {
    font-size: ${ props => props.theme.size.ls };
    text-transform: uppercase;
    font-weight: ${ props => props.theme.weight.bold };
    margin-bottom: ${ props => props.theme.spacing.ms };
  }
  ul {
    list-style: none;
  }
  li {
    font-weight: ${ props => props.theme.weight.medium };
    display: flex;
    align-items: center;
    line-height: 2;
    a {
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: underline;
      }
    }
    .footer-icon {
      padding-right: ${ props => props.theme.spacing.xxs };
      transform: translateY(8%);
    }
  }
`

const Li = styled.li`
  ${ applyStyleModifiers(ITEM_MODIFIERS) };
`

FooterNav.propTypes = {
  title: PropTypes.string,
  navItems: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    to: PropTypes.string,
  })),
  hasContacts: PropTypes.bool,
  hasAddress: PropTypes.bool,
}

FooterNav.defaultProps = {
  title: '',
  to: null,
  navItems: [],
  hasContacts: false,
  hasAddress: false,
}

const query = graphql`
  query FooterContacts {
    site {
      siteMetadata {
        contact {
          link
          type
          value
        }
        address
      }
    }
  }
`

export default FooterNav
