import React from 'react'
import {
  Container,
  Title,
  Reveal
} from 'components/atoms'
import { Slider } from 'components/molecules'
import { Element } from 'react-scroll'
import { FormattedMessage } from 'react-intl'

const Gallery = () => {
  return (
    <Element name="gallery">
      <Container modifiers={['fixTop']}>
        <Reveal effect="fadeInBottom">
          <Title modifiers={['ml', 'weightMedium']} withSeperator>
            <FormattedMessage id="gallery.title" />
          </Title>
        </Reveal>
      </Container>
      <Container modifiers={['bottomOnly']}>
        <Slider />
      </Container>
    </Element>
  )
}

export default Gallery
