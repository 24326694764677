import React from 'react'
import { Text } from 'components/atoms'

const Copyright = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <Text modifiers={['light', 'xxs']}>
      Caldeira Guesthouse © {year}
    </Text>
  )
}

export default Copyright
