import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Container, Text } from 'components/atoms'
import { FormattedMessage } from 'react-intl'

const references = [
  {
    'link': 'https://www.cntraveler.com/story/portugals-azores-islands-are-still-a-wild-paradise',
    'title': 'Conde Nast Traveler',
    key: 'traveler',
  },
  {
    'link': 'https://visao.sapo.pt/visaose7e/2015-03-30-100-razoes-para-visitar-os-acores-1',
    'title': 'Visão',
    key: 'visao',
  },
  {
    'link': 'https://www.menshealth.com/fitness/g19522608/best-surfing-lessons-hotels-vacation/?slide=10',
    'title': "Men's Health",
    key: 'menshealth',
  },
  {
    'link': 'https://www.evasoes.pt/ar-livre/acores-pico-sao-jorge-e-faial-os-trilhos-mais-bonitos-para-percorrer-a-pe/547094/',
    'title': 'Evasões',
    key: 'evasoes'
  }
]

const Reference = () => {
  const referencesList = useStaticQuery(query)
  return (
    <ReferenceContainer modifiers={['fixTop']}>
      <Text modifiers={['light', 'xxs']}>
        <FormattedMessage id="mention" />
      </Text>
      <List>
        {references.map(({ title, link, key }) => (
          <a href={link}
            target="_blank"
            rel="noopener noreferrer"
            key={key}
          >
            <Logo>
              <img alt={title} src={referencesList[key].childImageSharp.fluid.src} />
            </Logo>
          </a>
        ))}
      </List>
    </ReferenceContainer>
  )
}

const ReferenceContainer = styled(Container)`
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  z-index: 999;
`

const List = styled.ul`
  list-style-type: none;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`

const Logo = styled.li`
  padding: ${ props => props.theme.spacing.xs };
  transition: opacity 0.2s ease;
  opacity: 1;
  &:hover {
    opacity: 0.9;
  }
`

const query = graphql`
  query References {

    menshealth: file(relativePath: { eq: "logos/mens-health.png" }) {
      childImageSharp {
        fluid(maxWidth: 110) {
          src
        }
      }
    }

    visao: file(relativePath: { eq: "logos/visao.png" }) {
      childImageSharp {
        fluid(maxWidth: 110) {
          src
        }
      }
    }

    traveler: file(relativePath: { eq: "logos/traveler.png" }) {
      childImageSharp {
        fluid(maxWidth: 110) {
          src
        }
      }
    }

    evasoes: file(relativePath: { eq: "logos/evasoes.png" }) {
      childImageSharp {
        fluid(maxWidth: 110) {
          src
        }
      }
    }
  }
`

export default Reference
