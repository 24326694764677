import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Seperator } from 'components/atoms'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { modifiersType } from 'types'
import { below } from 'utils/breakpoints'

const MODIFIERS = {
  m: ({ theme }) => `
    font-size: ${ theme.size.m };
  `,
  sm: ({ theme }) => `
  font-size: ${ theme.size.sm };
`,
  inverse: ({ theme }) => `
    color: ${ theme.color.baseInverse };
  `,
  weightMedium: ({ theme }) => `
    font-weight: ${ theme.weight.medium };
  `,
  ml: ({ theme }) => css`
    font-size: ${ theme.size.ml };
    ${ below.md`
        font-size: ${ theme.size.m };
    ` }
  `,
}

const Title = styled.h2`
  font-family: ${ props => props.theme.font.alternative };
  font-weight: 700;
  font-size: ${ props => props.theme.size.m };
  line-height: 1.5;
  ${ applyStyleModifiers(MODIFIERS) };
`

const TheTitle = ({ children, as, withSeperator, modifiers }) => {
  return (
    <>
      {withSeperator && <Seperator />}
      <Title as={as} modifiers={modifiers}>
        {children}
      </Title>
    </>
  )
}

TheTitle.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  withSeperator: PropTypes.bool,
  modifiers: modifiersType
}

TheTitle.defaultProps = {
  as: 'h2',
  withSeperator: false,
  modifiers: ''
}

export default TheTitle
