/*
  Centralized PropTypes
  Read more here: https://medium.freecodecamp.org/react-pattern-centralized-proptypes-f981ff672f3b
*/

import { shape, oneOfType, arrayOf, string } from 'prop-types'

export const themeType = shape({})
export const classType = string

export const modifiersType = oneOfType([string, arrayOf(string)])
export const navItemsType = arrayOf(shape({}))
