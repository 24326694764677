module.exports = {
  'contact': 'Contact us',
  'contact.short': 'Contact',
  'book': 'Book now',
  'book.short': 'Book',
  'translation': 'em Português',
  'comments': 'comments',
  'superb': 'Superb',
  'gallery.title': 'Photo gallery',
  'room': 'Room "{name}"',
  'view': 'View',
  'lagoon': `Fajã's lake`,
  '404.title': 'Page not found',
  '404.back': 'Go back to home page',
  'thank': 'Thank you',
  'email': 'Email address',
  'phone': 'Phone',
  'message': 'Message',
  'form.email': 'Your Email Address',
  'form.name': 'Your name',
  'form.message': 'How can we help?',
  'form.button.default': 'Send',
  'form.button.error': 'Error...',
  'form.button.success': 'Sent!',
  'form.captcha': 'To prevent spam we need you to verify that you are not a robot...',
  'mention': 'Mentioned in'
}
