import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Title, NavIcon, NavItem, Button } from 'components/atoms'
import { Link } from 'gatsby'
import { classType, navItemsType } from 'types'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { FormattedMessage } from 'react-intl'

const NavMobile = ({ className, small, navItems, homeLink, langBtn, langContact }) => {
  const [isOpen, setOpen] = useState()

  const title = useMemo(() => {
    if (small && !isOpen) return <Title as="h1" modifiers={['sm']}>Caldeira</Title>
    return (
      <Title as="h1">Caldeira</Title>
    )
  }, [small, isOpen])

  const langNotSelected = useMemo(() => {
    return langBtn.find(lang => lang.selected === false)
  }, [langBtn])

  return (
    <>
    <Nav className={className} isOpen={isOpen}>
      <Nav.Start to={homeLink}>
        {title}
      </Nav.Start>
      <Nav.End>
        <Link to={langContact}>
          <Button modifiers={['primary', 'small']}>
            <FormattedMessage id="contact.short" />
          </Button>
        </Link>
        <Nav.IconFix>
          <NavIcon onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
        </Nav.IconFix>
      </Nav.End>
    </Nav>
      {isOpen && (
        <Nav.Menu>
          <NavContainer>
            {navItems.map(item => (
              <NavItem
                modifiers={['mobile']}
                to={item.to}
                key={item.key}
                link={item.link}
                onClick={() => setOpen(false)}
              >
                {item.text}
              </NavItem>
            ))}
            <NavItem modifiers={['primary', 'mobile']} link={langContact}>
              <FormattedMessage id="contact" />
            </NavItem>
            <Nav.Lang to={langNotSelected.link}>
              <FormattedMessage id="translation" />
            </Nav.Lang>
          </NavContainer>
        </Nav.Menu>
      )}
    </>
  )
}

NavMobile.propTypes = {
  className: classType,
  small: PropTypes.bool,
  navItems: navItemsType,
  homeLink: PropTypes.string,
}

NavMobile.defaultProps = {
  className: '',
  small: false,
  navItems: [],
  homeLink: '/',
}

const Nav = styled.nav`
  width: 100%;
  background-color: ${ props => props.theme.color.bgLighter };
  top: 0;
  left: 0;
  z-index: ${ props => props.theme.zIndex.l };
  height: auto;
  align-items: center;
  justify-content: space-between;
`

Nav.Start = styled(Link)`
  text-decoration: none;
  color: ${ props => props.theme.color.base };
  z-index: 999;
`

Nav.End = styled.div`
  display: flex;
  align-items: center;
`
Nav.IconFix = styled.div`
  transform: translateY(-3px);
`

Nav.Menu = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${ props => transparentize(0.7, props.theme.color.bg) };
  position: absolute;
  top: 0;
  left: 0;
  position: relative;
`

Nav.Lang = styled(Link)`
  letter-spacing: 1.3px;
  padding: ${ props => props.theme.spacing.m } ${ props => props.theme.spacing.xs };
  border-radius: ${ props => props.theme.value.borderRadius };
  transition: all 0.1s ease-out;
  cursor: pointer;
  text-decoration: none;
  color: ${ props => props.theme.color.base };
  font-size: ${ props => props.theme.size.xxs };
  &:hover {
    color: ${ props => props.theme.color.primary };
  }
  &.active {
    color: ${ props => props.theme.color.primary };
  }
  &:focus {
    background-color: ${ props => props.theme.color.bg };
    color: ${ props => props.theme.color.primary };
    outline: none;
  }
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export default NavMobile
