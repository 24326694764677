import React from 'react'
import styled from 'styled-components'
import RevealBase from 'react-reveal/Reveal'
import PropTypes from 'prop-types'

const Reveal = ({ effect, children, ...rest }) => {
  return (
    <AnimationStyles>
      <RevealBase effect={effect} {...rest}>
        {children}
      </RevealBase>
    </AnimationStyles>
  )
}

Reveal.propTypes = {
  children: PropTypes.node.isRequired,
  effect: PropTypes.oneOf(
    [
      'fadeInTop',
      'fadeInBottom',
      'fadeInLeft',
      'fadeInRight',
      'fadeIn'
    ]
  ),
}

Reveal.defaultProps = {
  effect: 'fadeInTop',
}

const AnimationStyles = styled.div`
  .fadeInRight{
    animation-name:fadeInRight;
  }
  .fadeInLeft{
    animation-name:fadeInLeft;
  }
  .fadeInTop{
    animation-name:fadeInTop;
  }
  .fadeInBottom{
    animation-name:fadeInBottom;
  }
  .fadeIn{
    animation-name:fadeIn;
  }

  @keyframes fadeInRight{
    0%{
      opacity:0;
      transform:translate(10%,0)
    }
    to{
      opacity:1;
      transform:translateX(0)
    }
  }
  @keyframes fadeInLeft{
    0%{
      opacity:0;
      transform:translate(-10%,0)
    }
    to{
      opacity:1;
      transform:translateX(0)
    }
  }
  @keyframes fadeInTop{
    0%{
      opacity:0;
      transform:translate(0,-2%)
    }
    to{
      opacity:1;
      transform:translateY(0)
    }
  }
  @keyframes fadeInBottom{
    0%{
      opacity:0;
      transform:translate(0,8%)
    }
    to{
      opacity:1;
      transform:translateY(0)
    }
  }
  @keyframes fadeIn{
    0%{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
`

export default Reveal
