import React from 'react'
import styled, { css, withTheme } from 'styled-components'
import { above, below } from 'utils/breakpoints'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import {
  Container,
  Text,
  Content,
  ButtonLine,
  Spacer
} from 'components/atoms'
import { scroller } from 'react-scroll'

const translation = {
  pt: {
    transport: {
      image: {
        key: 'transport',
        alt: 'Dois homems numa Moto 4. Estrada de terra à beira de uma ravina. Montanha verde. Céu azul. Prancha de surf na moto.',
        title: 'Transporte entre a Fajã dos Cubres e a Caldeira',
      },
      text: [
        'O acesso à Fajã de Santo Cristo só é possível de moto 4, a pé ou de barco. Os carros apenas podem ir até à Fajã vizinha: Fajã dos Cubres.',
        'Disponibilizamos: Transfer Aeroporto / Cais - Fajã Cubres Transfer Aeroporto / Cais - Serra do Topo Transporte cargas Fajã Cubres - Surfcamp (Moto4)'
      ],
      action: 'Contactar',
      link: '/contact'
    },
    openSpace: {
      image: {
        key: 'openSpace',
        title: 'Open space',
        alt: 'Sala de uma casa, com janelas, camas e chão de madeira.',
      },
      title: 'Produto da reabilitação de uma casa rural localizada na Fajã de Santo Cristo, este é o local ideal para quem pretende umas férias ao ritmo da natureza.',
      text: [
        'Graças à ondulação aqui existente e ao extraordinário envolvimento paisagístico, encontramo-nos no local perfeito para quem deseja umas férias longe da rotina. Após um passeio seguido de um banho na cascata, um mergulho na lagoa ou mesmo depois de apanhar umas ondas, a Caldeira GuestHouse&SurfCamp é local ideal para repousar.',
        'Temos 3 quartos com wc privado (com possibilidade para cama de casal ou singles), duas mezzanines e um open-space com excelentes condições ao seu dispor! Caso necessite também pode ter pequeno almoço incluído.'
      ],
      action: 'Ver galeria'
    },
    accomodation: {
      image: {
        key: 'accomodation',
        alt: 'Casa vista de fora com porta aberta e janela. Luz dentro da casa, cão a olhar para o interior da casa. Pessoa a cozinhar. Guitarra. Barbatanas. Chaminé.',
        title: 'Entrada da cozinha',
      },
      text: [
        'O camp disponibiliza serviços para diversos tipos de eventos, tais como: casamentos, baptizado, festas, almoços/jantares tradicionais, entre outros.',
        'Já realizamos diversos eventos, desde casamentos a eventos relacionais com yoga e fotografia, como a tour de fotografia dos açores.'
      ],
      action: 'Contactar',
      link: '/contact'
    },
  },
  en: {
    transport: {
      image: {
        key: 'transport',
        alt: 'Two men in quad-bike. Dirt road close to cliff.. Green mountain. Blue sky. Surfboard on the quad-bike.',
        title: 'Transportation between Fajã de Santo Cristo and Fajã dos Cubres.',
      },
      text: [
        'The access to Fajã de Santo Cristo is made by quad bike, boat or on foot. Cars can only go as far as the closest fajã, Fajã dos Cubres.',
        'We provide: Transfer from the airport/port to Fajã Cubres; Transfer from the airport/port to Serra do Topo; Luggage transport from Fajã Cubres to the Surfcamp (quad bike).'
      ],
      action: 'Contact us',
      link: '/en/contact'
    },
    openSpace: {
      image: {
        key: 'openSpace',
        title: 'Open space',
        alt: 'Room of an house with windows. Wood floor, beds and a sofa. Day light.',
      },
      title: 'A restored rural house located in Fajã de Santo Cristo, this is the ideal place for those who wish to enjoy holidays to the rhythm of nature.',
      text: [
        'Because of the waves existing in this fajã, and the extraordinary surrounding landscape, this is the perfect location for some holidays away from routine. After a walk and a dip in the waterfall, a dive in the lake or after riding some waves, Caldeira GuestHouse and SurfCamp is the ideal place to rest.',
        'We have three bedrooms (option of double or single beds) with private WC, two mezzanines and one open-space with excellent conditions. If you wish, breakfast can be included.'
      ],
      action: 'View gallery'
    },
    accomodation: {
      image: {
        key: 'accomodation',
        alt: 'Side of an old dark rock house. A door is open with light coming from the inside. A man is inside while a dog stands still on the door way looking at him.  The man is cooking. Outside the house there is a guitar and some fins leaning agaisnt the wall.',
        title: 'Kitchen entrance',
      },
      text: [
        'In our Camp we organize several events, such as weddings, christenings, parties, traditional lunches/dinners, among others.',
        'So far, we have carried out quite a few celebrations, from weddings to yoga and photography related events, namely the Azores Photography Tours.'
      ],
      action: 'Contact us',
      link: '/contact'
    },
  }
}

const query = graphql`
  query {
    accomodation: file(relativePath: { eq: "alojamento.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    openSpace: file(relativePath: { eq: "open-space.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    transport: file(relativePath: { eq: "transporte.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Accomodation = ({ data, copy }) => (
  <Column>
    <div className="content-img">
      <Image
        image={data[copy.image.key]}
        alt={copy.image.alt}
      />
      <Text className="image-title">{copy.image.title}</Text>
    </div>
    <Content>
      {copy.text.map(content => (
        <Text key={content}>{content}</Text>
      ))}
      <Link to={copy.link}>
        <ButtonLine>
          {copy.action}
        </ButtonLine>
      </Link>
    </Content>
  </Column>
)

const Transport = ({ data, copy }) => (
  <Column>
    <div className="content-img">
      <Image
        image={data[copy.image.key]}
        alt={copy.image.key}
      />
      <Text className="image-title">{copy.image.title}</Text>
    </div>
    <Content>
      {copy.text.map(content => (
        <Text key={content}>{content}</Text>
      ))}
      <Link to={copy.link}>
        <ButtonLine>
          {copy.action}
        </ButtonLine>
      </Link>
    </Content>
  </Column>
)

const OpenSpace = ({ data, copy }) => (
  <Column>
    <div className="content-center">
      <Spacer bottom="s">
        <Text modifiers={['sm', 'medium']}>{copy.title}</Text>
      </Spacer>
      <Text>
        {copy.text[0]}
      </Text>
    </div>
    <Image
      image={data[copy.image.key]}
      alt={copy.image.alt}
    />
    <Text className="image-title">{copy.image.title}</Text>
    <Content>
      <Text>
        {copy.text[1]}
      </Text>
      <ButtonLine onClick={() => scroller.scrollTo('gallery', {
        duration: 2000,
        smooth: true,
        offset: -80
      })}>
        {copy.action}
      </ButtonLine>
    </Content>
  </Column>
)

const Image = withTheme(({ theme, alt = '', image }) => {
  return (
    <Zoom wrapStyle={{ width: '100%' }}>
      <Img
        fluid={image.childImageSharp.fluid}
        style={{
          height: 'auto',
          width: '100%',
        }}
      />
    </Zoom>
  )
}
)

const ColumnsContainer = ({ className, langKey }) => {
  const data = useStaticQuery(query)
  const introData = translation[langKey]
  const { transport, accomodation, openSpace } = introData

  return (
    <div className={className}>
      <Container>
        <div className="desktop">
          <Columns>
            <Accomodation data={data} copy={accomodation} />
            <OpenSpace data={data} copy={openSpace} />
            <Transport data={data} copy={transport} />
          </Columns>
        </div>
        <div className="mobile">
          <Columns>
            <OpenSpace data={data} copy={openSpace} />
            <Accomodation data={data} copy={accomodation} />
            <Transport data={data} copy={transport} />
          </Columns>
        </div>
      </Container>
    </div>
  )
}

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  ${ above.md(props => css`
      grid-template-columns: 1fr 1.8fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
  `) }
}
`

const Column = styled.div(props => css`
  padding: 0 ${ props.theme.spacing.ms };
  ${ below.md`
    padding-right: 0;
    padding-left: 0;
  ` }
`)

export default styled(ColumnsContainer)`

  .image-title {
    padding-top: ${ props => props.theme.spacing.xxs };
    font-size: ${ props => props.theme.size.xxxs };
    color: ${ props => props.theme.color.baseLighter };
  }

  .content-center {
    padding: ${ props => props.theme.spacing.ms };
    padding-top: 0;
  }

  ${ below.md(props => css`
    .content-center {
      padding-right: ${ props.theme.spacing.s };
      padding-left: ${ props.theme.spacing.s };
    }
    .content-img {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        height: 400px !important;
        width: auto !important;
      }
    }
  `) }
`
