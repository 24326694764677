import React from 'react'
import styled from 'styled-components'
import { Card, Text, Spacer } from 'components/atoms'
import { useStaticQuery, graphql } from 'gatsby'

const GoogleMap = props => {
  const { site: { siteMetadata: { address } } } = useStaticQuery(query)
  return (
    <>
    <Wrapper>
      <iframe
        id="gmap_canvas"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12468.636366884348!2d-27.925691!3d38.622221!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcf9124e6e6396a17!2sCaldeira%20SurfCamp%20%26%20Guesthouse!5e0!3m2!1sen!2spt!4v1582726608044!5m2!1sen!2spt"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      >
        &nbsp;
      </iframe>
    </Wrapper>
      <Spacer top="xs">
        <Text modifiers={['xxs']}>
          {address}
        </Text>
      </Spacer>
      </>
  )
}

const Wrapper = styled(Card)`
  padding: 0;
  height: 270px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  iframe {
    height: 100%;
    width: 100%;
    border: none;
  }
`

const query = graphql`
  query MapAddress {
    site {
      siteMetadata {
        address
      }
    }
  }
`

export default GoogleMap
