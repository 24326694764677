module.exports = {
  'contact': 'Contactar',
  'contact.short': 'Contactar',
  'book': 'Reservar já',
  'book.short': 'Reservar',
  'translation': 'in English',
  'comments': 'comentários',
  'superb': 'Suberbo',
  'gallery.title': 'Galeria de fotos',
  'room': 'Quarto "{name}"',
  'view': 'Vista',
  'lagoon': 'Lagoa da Fajã',
  '404.title': 'Página não encontrada',
  '404.back': 'Voltar à página inicial',
  'thank': 'Obrigado',
  'email': 'Endereço de email',
  'phone': 'Telémovel',
  'message': 'Mensagem',
  'form.email': 'O seu endereço de email',
  'form.name': 'O seu nome',
  'form.message': 'Como podemos ajudar?',
  'form.button.default': 'Enviar',
  'form.button.error': 'Erro...',
  'form.button.success': 'Enviado!',
  'form.captcha': 'Para prevenir spam é necessário verificar que não é um robô...',
  'mention': 'Mencionado em'
}
