import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const WidgetBooking = ({ semanticText, numberOfComments, score }) => (
  <Widget
    href="https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.pt-pt.html"
    title="Booking.com - Caldeira Guesthouse"
    target="_blank"
    rel="noopener noreferrer"
  >
    <Widget.Initial>
      <Logo />
    </Widget.Initial>
    <Widget.End>
      <Widget.Reviews>
        <Widget.Text>{semanticText}</Widget.Text>
        <Widget.Comments>{`${ numberOfComments } `}
          <FormattedMessage
            id="comments"
          />
        </Widget.Comments>
      </Widget.Reviews>
      <Widget.Score>{score}</Widget.Score>
    </Widget.End>
  </Widget>
)

WidgetBooking.propTypes = {
  semanticText: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
  numberOfComments: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired
}

const Widget = styled.a`
  display: inline-flex;
  padding: ${ props => props.theme.spacing.xxms };
  align-items: center;
  transition: background-color 0.1s ease-out;
  border-radius: ${ props => props.theme.value.borderRadius };

  text-decoration: none;
  color: ${ props => props.theme.color.base };

  &:hover {
    background-color: ${ props => props.theme.color.bg };
    cursor: pointer;
  }
`

Widget.Initial = styled.div`
  padding-right: ${ props => props.theme.spacing.xs };
  svg {
    height: 37px;
    width: 37px;
    transform: translateY(2px);
  }
`

Widget.End = styled.div`
  display: flex;
`

Widget.Score = styled.div`
  font-size: ${ props => props.theme.size.xs };
  color: ${ props => props.theme.color.baseInverse };
  padding: ${ props => props.theme.spacing.xxs };
  background-color: ${ props => props.theme.color.booking };
  border-radius: 8px 8px 0 8px;
  font-weight: ${ props => props.theme.weight.medium };
  height: 36px;
  display: flex;
  align-items: center;
`

Widget.Reviews = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: ${ props => props.theme.spacing.xs };
`

Widget.Text = styled.span`
  color: ${ props => props.theme.color.booking };
  font-weight: 500;
  white-space: nowrap;
`

Widget.Comments = styled.span`
  color: ${ props => props.theme.color.baseLigther };
  font-size: ${ props => props.theme.size.xxs };
  white-space: nowrap;
`

const Logo = () => (
  <svg
    height="32"
    width="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M6.004 31.274H.012v-5.889L.01 25.27V6.005A6.008 6.008 0 0 1 6.016 0h19.967a6.008 6.008 0 0 1 6.006 6.005V25.27a6.009 6.009 0 0 1-6.006 6.004H6.004z"
        fill="#0C3B7C"
      />
      <path
        d="M14.544 20.701l-2.576-.003v-3.08c0-.657.256-1 .819-1.078h1.757c1.253 0 2.064.79 2.064 2.069 0 1.313-.79 2.09-2.064 2.092zm-2.576-8.307v-.81c0-.71.3-1.047.959-1.09h1.318c1.13 0 1.807.675 1.807 1.808 0 .862-.464 1.869-1.766 1.869h-2.318v-1.777zm5.865 3.074l-.466-.262.407-.348c.473-.406 1.266-1.32 1.266-2.898 0-2.417-1.874-3.975-4.774-3.975h-3.31v-.002h-.377A1.615 1.615 0 0 0 9.022 9.58V23.29h5.309c3.223 0 5.304-1.755 5.304-4.473 0-1.464-.672-2.714-1.802-3.35z"
        fill="#FFF"
      />
      <path
        d="M21.018 21.397a1.89 1.89 0 0 1 1.885-1.895 1.894 1.894 0 0 1 0 3.79 1.89 1.89 0 0 1-1.885-1.895"
        fill="#00BAFC"
      />
    </g>
  </svg>
)

export default WidgetBooking
