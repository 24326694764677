import styled from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { above } from 'utils/breakpoints'

const MODIFIERS = {
  fixTop: () => `
    padding-top: 0 !important;
  `,
  fixBottom: () => `
    padding-bottom: 0 !important;
  `,
  fixLeft: () => `
    padding-left: 0 !important;
  `,
  horizontal: () => `
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  `,
  leftOnly: () => `
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  `,
  bottomOnly: () => `
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  `,
  smallTop: ({ theme }) => `
    padding-top: ${ theme.spacing.s } !important;
  `,
  fixNav: () => `
    margin-top: 92px;
  `
}

const Container = styled.div`
  width: 100%;
  padding: ${ props => props.theme.spacing.s } ${ props => props.theme.spacing.ms };
  ${ above.md`
      padding: ${ props => props.theme.spacing.l } ${ props =>
  props.theme.spacing.xl };
  ` };
  ${ applyStyleModifiers(MODIFIERS) };
`

export const ContainerVertical = styled.div`
  width: 100%;
  padding: ${ props => props.theme.spacing.ms } 0;
  ${ applyStyleModifiers(MODIFIERS) };
`

export default Container
