// eslint-disable react/style-prop-object
// eslint-disable max-len
import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { themeType } from 'types'

const getWidth = (originalWidth, originalHeight, currentHeight) =>
  Math.round(currentHeight * (originalWidth / originalHeight))

const Icon = ({ icon, color, height, theme, modifiers, className }) => {
  const isInverseModifierActive =
    typeof modifiers === 'string'
      ? modifiers === 'inverse'
      : modifiers.includes('inverse')
  const iconColor =
    color ||
    (isInverseModifierActive ? theme.color.baseInverse : theme.color.base)

  switch (icon) {
  case 'star':
    return (
      <svg
        width={getWidth(20, 19, height)}
        height={height}
        viewBox="0 0 20 19"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g fill="none" fillRule="evenodd">
          <path d="M-2-2h24v24H-2z" />
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19z"
          />
          <path d="M-2-2h24v24H-2z" />
        </g>
      </svg>
    )
  case 'chevron-right':
    return (
      <svg
        width={getWidth(8, 12, height)}
        height={height}
        viewBox="0 0 8 12"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill={iconColor}
            fillRule="nonzero"
            d="M2 0L.59 1.41 5.17 6 .59 10.59 2 12l6-6z"
          />
          <path d="M-8-6h24v24H-8z" />
        </g>
      </svg>
    )
  case 'arrow':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(49, 7, height)}
        height={height}
        viewBox="0 0 49 7"
        className={className}
      >
        <path
          fill={iconColor}
          fillRule="evenodd"
          d="M42 0l7 3.5L42 7V4.054H.889v-1h41.11L42 0z"
        />
      </svg>
    )
  case 'location':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(24, 24, height)}
        height={height}
        viewBox="0 0 24 24"
      >
        <path
          fill={iconColor}
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
    )
  case 'link':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(24, 24, height)}
        height={height}
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill={iconColor} d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"></path>
      </svg>
    )
  case 'arrow-next':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(24, 24, height)}
        height={height}
        viewBox="0 0 24 24"
      >
        <path fill={iconColor} d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
        <path fill="none" d="M0 0h24v24H0V0z"></path>
      </svg>
    )
  case 'arrow-previous':
    return (
      <svg
        width={getWidth(24, 24, height)}
        height={height}
        viewBox="0 0 24 24"
      >
        <path fill={iconColor} d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
        <path fill="none" d="M0 0h24v24H0V0z"></path>
      </svg>
    )
  case 'marker':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(17, 24, height)}
        height="24"
        viewBox="0 0 17 24"
      >
        <path
          fill="#B8E986"
          d="M8.47 0C3.815 0 .027 3.788.027 8.444c0 5.779 7.557 14.262 7.879 14.62a.76.76 0 001.131 0c.322-.358 7.88-8.841 7.88-14.62C16.915 3.788 13.125 0 8.47 0zm0 12.693a4.253 4.253 0 01-4.248-4.249 4.253 4.253 0 014.249-4.248 4.253 4.253 0 014.248 4.248 4.253 4.253 0 01-4.248 4.25z"
        ></path>
      </svg>
    )
  case 'email':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(24, 24, height)}
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill={iconColor} d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
        <path fill="none" d="M0 0h24v24H0z"></path>
      </svg>
    )
  case 'phone':
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={getWidth(24, 24, height)}
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill={iconColor} d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
      </svg>
    )
  default:
    console.error('Invalid Icon prop')
    return null
  }
}

Icon.propTypes = {
  icon: PropTypes.oneOf([
    'star',
    'arrow',
    'chevron-right',
    'location',
    'link',
    'arrow-previous',
    'arrow-next',
    'marker',
    'phone',
    'email'
  ])
    .isRequired,
  color: PropTypes.string,
  height: PropTypes.number,
  modifiers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  theme: themeType.isRequired,
  className: PropTypes.string
}

Icon.defaultProps = {
  color: null,
  height: 24,
  modifiers: [],
  className: ''
}

export default withTheme(Icon)
