import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { applyStyleModifiers } from 'styled-components-modifiers'
import { lighten } from 'polished'
import { CircleSpinner } from 'react-spinners-kit'

const MODIFIERS = {
  stretched: ({ theme }) => `
    padding: ${ theme.spacing.xs };
    font-size: ${ theme.size.xs };
    padding-right: ${ theme.spacing.m };
    padding-left: ${ theme.spacing.m };
  `,
  small: ({ theme }) => css`
    padding: 6px 12px;
    font-size: ${ theme.size.xxs };
    box-shadow: none;
    letter-spacing: normal;
    font-weight: ${ theme.weight.medium };
    &:hover {
      box-shadow: none;
      transform: translate3d(0px,-0px,0px);
      background-color: ${ lighten(0.05, theme.color.primary) };
    }
    &:focus {
      box-shadow: none;
      transform: translate3d(0px,-0px,0px);
      background-color: ${ lighten(0.03, theme.color.primary) };
      outline: none;
    }
  `,
  lighter: ({ theme }) => css`
    background-color: ${ theme.color.bgGrey };
    color: ${ theme.color.base };
    &:hover {
      background-color: ${ lighten(0.05, theme.color.bgGrey) };
      color: ${ theme.color.base };
    }
    &:focus {
      color: ${ theme.color.base };
      background-color: ${ lighten(0.03, theme.color.bgGrey) };
    }
  `,
  danger: ({ theme }) => css`
    background-color: ${ theme.color.danger };
    color: ${ theme.color.baseInverse };
    &:hover {
      background-color: ${ lighten(0.05, theme.color.danger) };
      color: ${ theme.color.baseInverse };
    }
    &:focus {
      color: ${ theme.color.baseInverse };
      background-color: ${ lighten(0.03, theme.color.danger) };
    }
  `,
  disabled: () => `
    pointer-events: none;
  `,
  submit: ({ theme }) => css`
    padding: 1.5rem 2rem;
    text-transform: uppercase;
    font-weight: ${ theme.weight.bold };
    font-size: ${ theme.size.xxs };
  `
}

const Button = ({ children, onClick, isDisabled, isLoading, ...props }) => {
  const handleOnClick = () => {
    if (isDisabled) return null
    onClick()
  }
  return (
    <Btn {...props} isLoading={isLoading} onClick={handleOnClick}>
      <span className="btn-loader">
        <CircleSpinner size={16} />
      </span>
      <span className="btn-child">
        {children}
      </span>
    </Btn>
  )
}

const Btn = styled.button`
  padding: ${ props => props.theme.spacing.xs };
  border-radius: ${ props => props.theme.value.borderRadius };
  transition: all 0.1s ease-out;
  cursor: pointer;
  text-decoration: none;
  color: ${ props => props.theme.color.base };
  background-color: ${ props => props.theme.color.primary };
  color: ${ props => props.theme.color.baseInverse };
  border: none;
  transition: all 0.2s ease;
  box-shadow: 0 5px 10px rgba(0,0,0,0.12);
  &:hover {
    box-shadow: 0 8px 30px rgba(0,0,0,0.12);
    transform: translate3d(0px,-1px,0px);
    background-color: ${ props => lighten(0.05, props.theme.color.primary) };
    color: ${ props => props.theme.color.baseInverse };
  }
  &:focus {
    color: ${ props => props.theme.color.baseInverse };
    background-color: ${ props => lighten(0.03, props.theme.color.primary) };
    outline: none;
  }
  ${ applyStyleModifiers(MODIFIERS) };

  position: relative;
  .btn-loader {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.4s ease;
    opacity: ${ props => props.isLoading ? 1 : 0 };
  }
  .btn-child {
    transition: opacity 0.4s ease;
    opacity: ${ props => props.isLoading ? 0 : 1 };
  }
`

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  onClick: () => null,
  isDisabled: false,
  isLoading: false,
}

export default Button
