import React from 'react'
import {
  Container,
  Title,
  Spacer,
  Reveal
} from 'components/atoms'
import { TestimonialCard, CardAction } from 'components/molecules'
import styled, { css } from 'styled-components'
import { above, below } from 'utils/breakpoints'
import { FormattedMessage } from 'react-intl'

const translationsStart = {
  pt: [
    {
      name: 'Raquel Duarte',
      message: 'Tudo o que lhe envolve torna a estadia incrivel, primeiro a que diz respeito ao alojamento a simpatia, disponibilidade de todos, a qualidade das refeiçoes, a limpeza e o cuidado, o carinho com que fomos tratados. Seria 6 estrelas se possível. Quanto a tudo que é exterior ao surf camp relativo a natureza e o que nos nos rodeia, na forma selvagem, de uma montanha imponente que nos corta a respiraçao, é um sitio para apaziguar a alma e preencher o coraçao. Voltarei em breve...',
      link: 'https://www.facebook.com/raquel.duarte.50767984/posts/10157756156835884'
    }
  ],
  en: [
    {
      name: 'André Batalha',
      message: `A magical place with the friendliest people! David's 11 years work and Neide's loving-kindness made my old dream come true. It's a fortune to have such a place in the middle of the Atlantic surrounded by beautiful nature. I want to come back!`,
      link: 'https://www.facebook.com/andrebatalha84/posts/10154290755327146'
    }
  ]
}

const translationsEnd = {
  pt: [
    {
      name: 'Menina',
      message: `Inesquecível para todos, miúdos e graúdos e nenhum de nós é surfista! O surfcamp está muito bem localizado, com espaço amplo em frente à casa e sempre com vista para o mar! A serenidade do espaço e das pessoas que nos receberam e cuidaram de nós foi contagiante! Como seria bom ficar mais tempo... É para voltar`,
      link: 'https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.en.html?tab=4;rid=1627740032;aid=1213434;label=metagha-link-reviewsPT-hotel-1554077_dev-desktop_los-1_lang-en;checkin=2020-03-08;checkout=2020-03-09;lang=en;utm_source=metagha;utm_medium=reviews;utm_campaign=PT;utm_term=hotel-localuniversal;utm_content=los-1_lang-en;advance_booking_window=9'
    },
    {
      name: 'Carina',
      message: 'Dormir na caldeira guesthouse é uma experiência única. A faja de santo Cristo é um paraíso de onde não se quer sair, e este alojamento tem um ambiente descontraído que nos encantou mal chegámos. Ficámos num quarto com cama de casal e WC privado, que tinha todo o conforto. O staff esteve disponível para conversar e nos fazer sentir em casa. É daqueles lugares tao bons, que até tens vontade de guardar segredo sobre ele.',
      link: 'https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.en.html?tab=4;rid=1627740032;aid=1213434;label=metagha-link-reviewsPT-hotel-1554077_dev-desktop_los-1_lang-en;checkin=2020-03-08;checkout=2020-03-09;lang=en;utm_source=metagha;utm_medium=reviews;utm_campaign=PT;utm_term=hotel-localuniversal;utm_content=los-1_lang-en;advance_booking_window=9'
    }
  ],
  en: [
    {
      name: 'Asli',
      message: `This is a truly magical spot. Emerging after a nice walk onto a beautiful embankment by the water, overseen by tall rockfaces and all shades of green, was really something. David and his staff are caring and attentive. Facilities are clean and kitchen is stocked. If you want to get off the path and sleep with the ocean waves, this is the place for you.`,
      link: 'https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.en.html?tab=4;rid=1627740032;aid=1213434;label=metagha-link-reviewsPT-hotel-1554077_dev-desktop_los-1_lang-en;checkin=2020-03-08;checkout=2020-03-09;lang=en;utm_source=metagha;utm_medium=reviews;utm_campaign=PT;utm_term=hotel-localuniversal;utm_content=los-1_lang-en;advance_booking_window=9'
    },
    {
      name: 'Marcin',
      message: `Location is amazing, sound of the waves rolling in at night, Scenery is beautiful, and David is friendly and helpful throughout, if you like wilderness, raw beauty and stunning scenery and a get away from it all location then this is a must. Great place and look forward to visiting again for longer.`,
      link: 'https://www.booking.com/hotel/pt/caldeira-guesthouse-calheta.en.html?tab=4;rid=1627740032;aid=1213434;label=metagha-link-reviewsPT-hotel-1554077_dev-desktop_los-1_lang-en;checkin=2020-03-08;checkout=2020-03-09;lang=en;utm_source=metagha;utm_medium=reviews;utm_campaign=PT;utm_term=hotel-localuniversal;utm_content=los-1_lang-en;advance_booking_window=9'
    }
  ]
}

const translation = {
  pt: {
    title: 'O que dizem os nossos hóspedes?',
    action: 'Esperamos o seu contacto',
    link: '/contact',
    description: 'Venha aproveitar o sossego da Fajã.'
  },
  en: {
    title: 'What do our guests say?',
    action: 'We look forward to hearing from you',
    link: '/en/contact',
    description: `Come and enjoy Fajã's tranquility`
  }
}

const Path = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="918"
    viewBox="0 0 96 918"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#EAEAEA"
      strokeDasharray="1,40"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="12"
      d="M80.079 5.914C30.693 80.94 6 164.767 6 257.39c0 138.937 84.095 113.155 84.095 264.982 0 151.827-25.697 153.2-39.197 234.7-9 54.333-8.133 113.976 2.602 178.928"
    ></path>
  </svg>
)

const Testimonials = ({ langKey }) => {
  const testimonialsDataStart = translationsStart[langKey]
  const testimonialsDataEnd = translationsEnd[langKey]
  const data = translation[langKey]

  return (
    <Container modifiers={['fixBottom']}>
      <Columns>
        <Column>
          <TitleWrapper>
            <Reveal effect="fadeInBottom">
              <Title modifiers={['ml', 'weightMedium']} withSeperator>
                {data.title}
              </Title>
            </Reveal>
          </TitleWrapper>
          <TestimonialsContainer>
            {
              testimonialsDataStart.map(testimonial => (
                <Reveal effect="fadeInLeft" key={testimonial.name}>
                  <CardWrapper className="card-wrapper--left">
                    <a rel="noopener noreferrer" href={testimonial.link} target="_blank" >
                      <TestimonialCard name={testimonial.name} message={testimonial.message} />
                    </a>
                  </CardWrapper>
                </Reveal>
              ))
            }
            <Reveal effect="fadeInLeft">
              <CardAction
                title={data.action}
                message={data.description}
                action={<FormattedMessage id="contact.short" />}
                link={data.link}
              />
            </Reveal>
          </TestimonialsContainer>
        </Column>
        <div className="path-container">
          <div className="path">
            <Reveal effect="fadeInTop">
              <Path />
            </Reveal>
          </div>
        </div>
        <Column>
          <TestimonialsContainer>
            <Spacer top="xl" isDesktop>
              {
                testimonialsDataEnd.map(testimonial => (
                  <Reveal effect="fadeInRight" key={testimonial.name}>
                    <CardWrapper className="card-wrapper--right">
                      <a rel="noopener noreferrer" href={testimonial.link} target="_blank" >
                        <TestimonialCard name={testimonial.name} message={testimonial.message} />
                      </a>
                    </CardWrapper>
                  </Reveal>
                ))
              }
            </Spacer>
          </TestimonialsContainer>
        </Column>
      </Columns>
    </Container>
  )
}

const Columns = styled.div`
  display: grid;
  .path-container {
    position: relative;
    display: none;
  }

  .path {
    position: absolute;
    transform: translateY(-5%);
    width: 90%;
  }

  a {
    text-decoration: none;
    &:hover,&:focus,&:active {
      color: ${ props => props.theme.color.base } ;
    }
  }

  ${ above.md(props => css`
      grid-template-columns: 1fr 84px 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: ${ props => props.theme.spacing.xs };
      grid-row-gap: 0px;

      .path-container {
        display: block;
      }

      .path {
        width: 100%;
      }
    `) }
  ${ above.lg(props => css`
    grid-column-gap: ${ props => props.theme.spacing.xxxl };
  `) }
`

const Column = styled.div(props => css`
  padding: 0 ${ props.theme.spacing.ms };
  ${ below.md(props => css`
    padding: 0;
  `) }
`)

const TitleWrapper = styled.div`
  max-width: 400px;
  padding-bottom: ${ props => props.theme.spacing.xxl };
  ${ below.md`
    padding-bottom: ${ props => props.theme.spacing.xs };
  ` }
`

const TestimonialsContainer = styled.div`
  ${ below.md`
    padding: 0;
    padding-bottom: ${ props => props.theme.spacing.m };
  ` }

`

const CardWrapper = styled.div`
  max-width: 560px;
  padding-bottom: ${ props => props.theme.spacing.xxxl };
  .card-wrapper--right {
    margin-right: auto;
  }
  .card-wrapper--left {
    margin-left: auto;
  }
  ${ below.md`
    max-width: 100%;
    padding-bottom: ${ props => props.theme.spacing.m };
  ` }
`

export default Testimonials
