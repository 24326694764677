import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Text, Icon } from 'components/atoms'
import { FormattedMessage } from 'react-intl'

const Contacts = () => {
  const data = useStaticQuery(query)
  const { site: { siteMetadata: { contact } } } = data
  return (
    <List>
      {contact.map(({ link, type, value }) => (
        <li key={value}>
          <ItemHeader>
            <Icon icon={type} />
            <Text modifiers={['medium', 'ls']}>
              <FormattedMessage id={type} />
            </Text>
          </ItemHeader>

          <a href={link}>
            {value}
          </a>
        </li>
      ))}
    </List>
  )
}

const List = styled.ul`
  list-style-type: none;
  li {
    padding: ${ props => props.theme.spacing.s } ${ props => props.theme.spacing.xs };
    ${ Text } {
      padding-bottom: 0;
      padding-left: ${ props => props.theme.spacing.xs };
    }
  }
`

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${ props => props.theme.spacing.xs };
`

const query = graphql`
  query Contacts {
    site {
      siteMetadata {
        contact {
          link
          type
          value
        }
      }
    }
  }
`
export default Contacts
