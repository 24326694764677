import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const NavIcon = ({ onClick, isOpen }) => {
  const handleClick = () => {
    if (typeof onClick === 'function') onClick()
  }

  return (
    <Icon onClick={handleClick} isOpen={isOpen} role="button" tabIndex="0" >
      <Icon.LineTop isOpen={isOpen} />
      <Icon.LineBottom isOpen={isOpen} />
    </Icon>
  )
}

NavIcon.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}

NavIcon.defaultProps = {
  isOpen: false,
  onClick: null
}

const Icon = styled.div`
  cursor: pointer;
  height: 40px;
  width: 48px;
  padding: ${ props => props.theme.spacing.xxs } ${ props => props.theme.spacing.s };
  padding-left: ${ props => props.theme.spacing.base };
  padding-top: ${ props => props.theme.spacing.base };
  z-index: ${ ({ isOpen, theme }) =>
    isOpen ? theme.zIndex.above : theme.zIndex.m };
  position: relative;
`

const Line = styled.div`
  position: absolute;
  width: 24px;
  height: 1px;
  border-radius: 3px;
  background-color: ${ props => props.theme.color.bgInverse };
`

Icon.LineTop = styled(Line)`
  transform-origin: left center;
  transition: transform 200ms;
  transform: ${ ({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0deg)') };
`

Icon.LineBottom = styled(Line)`
  transform-origin: right center;
  transition: transform 200ms;
  transform: ${ ({ isOpen }) =>
    isOpen
      ? 'translate(-8px, 0) rotate(-45deg)'
      : 'translate(0px, 12px) rotate(0deg)' };
`

export default NavIcon
