import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'
import { Container } from 'components/atoms'
import { classType, navItemsType } from 'types'
import { useWindowScroll } from 'react-use'

const NavContainer = ({
  inverseTheme,
  className,
  navItems,
  homeLink,
  langBtn,
  isHome,
  langContact,
}) => {
  const { y } = useWindowScroll()
  const shouldMinimizeNav = y > 91

  return (
    <NavStyle hasShadow={shouldMinimizeNav}>
      <Container modifiers="horizontal" className={className}>
        <NavMobile
          navItems={navItems}
          homeLink={homeLink}
          small={shouldMinimizeNav}
          langBtn={langBtn}
          isHome={isHome}
          langContact={langContact}
          className="nav-mobile"
        />
        <NavDesktop
          navItems={navItems}
          homeLink={homeLink}
          small={shouldMinimizeNav}
          langBtn={langBtn}
          isHome={isHome}
          langContact={langContact}
          className="nav-desktop"
        />
      </Container>
    </NavStyle>
  )
}

NavContainer.propTypes = {
  className: classType,
  inverseTheme: PropTypes.bool,
  navItems: navItemsType,
  homeLink: PropTypes.string,
}

NavContainer.defaultProps = {
  className: '',
  inverseTheme: false,
  navItems: [],
  homeLink: '/'
}

const NavStyle = styled.div`
  position: fixed;
  top: 0;
  z-index: ${ props => props.theme.zIndex.above };
  transition: box-shadow 150ms;
  ${ props => props.hasShadow ? 'box-shadow: 0 17px 15px 0 rgba(0,0,0,0.03)' : '' };
  background-color: ${ props => props.theme.color.bgLighter };
  z-index: 100;
  height: auto;
  width: 100%;
`

export default NavContainer
