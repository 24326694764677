import React from 'react'
import { Card, Text, Content } from 'components/atoms'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import Img from 'gatsby-image'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

// todo images carousel
const ActivitieCard = ({ message, name, theme, images }) => {
  console.log(images)
  return (
    <CardTestimonial>
      <CardTitle>
        <Text modifiers={['sm', 'medium']}>
          {name}
        </Text>
      </CardTitle>
      <Text>
        {message}
      </Text>
      {images && images.length > 0 && (
        <Content modifiers={['fixHorizontal']}>
          <Zoom wrapStyle={{ width: '100%' }}>
            <Img
              fluid={images[0]}
              style={{
                height: 'auto',
                width: '100%',
              }}
            />
          </Zoom>
        </Content>
      )}
    </CardTestimonial>
  )
}

const CardTestimonial = styled(Card)(props => css`
  cursor: default;
  &:hover {
    box-shadow: 0 8px 15px 0 rgba(0,0,0,0.06);
  }
`)

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${ props => props.theme.spacing.xs };
`

ActivitieCard.propTypes = {
  message: PropTypes.string,
  name: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.shape({}),
}

ActivitieCard.defaultProps = {
  name: 'Actividade',
  message: '',
}

export default withTheme(ActivitieCard)
