import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Title, NavItem } from 'components/atoms'
import { Link } from 'gatsby'
import { classType, navItemsType } from 'types'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { lighten } from 'polished'

const NavDesktop = ({
  className,
  small,
  navItems,
  homeLink,
  langBtn,
  isHome,
  langContact
}) => {
  const langNotSelected = useMemo(() => {
    return langBtn.find(lang => lang.selected === false)
  }, [langBtn])

  return (
    <Nav className={className} small={small}>
      <Nav.Start to={homeLink}>
        <Title as="h1" modifiers={small ? ['sm'] : []}>
          Caldeira
          <span className="title--end"> Guesthouse & Surfcamp</span>
        </Title>
      </Nav.Start>
      <Nav.List>
        {navItems.map(item => (
          <Nav.Item key={item.key}>
            <NavItem to={item.to} isHome={isHome} link={item.link}>
              {item.text}
            </NavItem>
          </Nav.Item>
        ))}
        <Nav.Item>
          <NavItem modifiers="primary" link={langContact}>
            <FormattedMessage id="contact" />
          </NavItem>
        </Nav.Item>
        <Nav.Lang to={langNotSelected.link}>
          <FormattedMessage id="translation" />
        </Nav.Lang>
      </Nav.List>
    </Nav>
  )
}

NavDesktop.propTypes = {
  className: classType,
  small: PropTypes.bool,
  navItems: navItemsType,
  homeLink: PropTypes.string,
}

NavDesktop.defaultProps = {
  className: '',
  small: false,
  navItems: [],
  homeLink: '/'
}

const Nav = styled.nav`
  align-items: center;
  justify-content: space-between;
  background-color: ${ props => props.theme.color.bgLighter };
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${ props => props.theme.zIndex.l };
  transition: height 0.2s ease;
  height: ${ props => props.small ? '70px' : '92px' };
  .title--end {
    font-family: ${ props => props.theme.font.base };
    font-weight: ${ props => props.theme.weight.base };
    color: ${ props => lighten(0.2, props.theme.color.base) };
    transition: color 0.2s ease;
  }
  @media (min-width: 900px) and (max-width: 1255px) {
    .title--end {
      font-size: 1rem;
    }
  }
`

Nav.Lang = styled(Link)`
  margin-left: ${ props => props.theme.spacing.xs };
  letter-spacing: 1.3px;
  padding: ${ props => props.theme.spacing.xs };
  border-radius: ${ props => props.theme.value.borderRadius };
  transition: all 0.1s ease-out;
  cursor: pointer;
  text-decoration: none;
  color: ${ props => props.theme.color.base };
  font-size: ${ props => props.theme.size.xxs };
  &:hover {
    color: ${ props => props.theme.color.primary };
  }
  &.active {
    color: ${ props => props.theme.color.primary };
  }
  &:focus {
    background-color: ${ props => props.theme.color.bg };
    color: ${ props => props.theme.color.primary };
    outline: none;
  }
`

Nav.Start = styled(Link)`
  text-decoration: none;
  color: ${ props => props.theme.color.base };
  transition: color 0.2s ease;
  &:hover {
    color: ${ props => props.theme.color.primary };
    span {
      color: ${ props => props.theme.color.primary } !important;
    }
  }
`

Nav.List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
`

Nav.Item = styled.li`
  display: inline;
  margin-left: ${ props => props.theme.spacing.s };
`

export default NavDesktop
