import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

function SEO (
  {
    description,
    lang,
    meta,
    keywords,
    title,
    hasTitleTemplate,
    imageSrc,
  }
) {
  const imageUrl = ({ siteUrl, src }) => `${ siteUrl }${ withPrefix(src) }`

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title || data.site.siteMetadata.title}
            titleTemplate={hasTitleTemplate ? `%s - ${ data.site.siteMetadata.title }` : ''}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:image`,
                content: imageUrl({
                  siteUrl: data.site.siteMetadata.siteUrl,
                  src: imageSrc || data.file.childImageSharp.fluid.src
                })
              },
              {
                property: `og:image:width`,
                content: imageUrl({
                  siteUrl: data.site.siteMetadata.siteUrl,
                  src: imageSrc || data.file.childImageSharp.fluid.src
                })
              },
              {
                property: `twitter:image`,
                content: imageUrl({
                  siteUrl: data.site.siteMetadata.siteUrl,
                  src: imageSrc || data.file.childImageSharp.fluid.src
                })
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `)
                  }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `pt`,
  meta: [],
  keywords: [],
  hasTitleTemplate: true,
  imageSrc: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  hasTitleTemplate: PropTypes.bool,
  imageSrc: PropTypes.string,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  file(relativePath: {eq: "camp.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
  }
`
